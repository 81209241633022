import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
    Tabs,
    Tag,
    Space,
    Select,
    Spin,
    Divider,
    Typography,
    Alert,
    Form,
    Input,
    Button,
    Row,
    Col,
    Tooltip,
    Table,
    Popconfirm,
    message,
    Checkbox
} from 'antd';
import { QuestionCircleOutlined, MinusCircleOutlined, RightOutlined, LeftOutlined, CopyOutlined, DeleteOutlined, PlusCircleOutlined, BulbOutlined, CheckOutlined } from '@ant-design/icons';
import { useParams, Redirect, withRouter, useHistory } from 'react-router-dom';
import { OrderPdf } from '../';
import { GET_CUSTOMERS, GET_ASSIGNEE, GET_FAXES, GET_USER_ONLINE, GET_ORDER, GET_PRODUCTS, GET_CUSTOMER, GET_ORDER_PRODUCTS, GET_PRODUCT_RECOMMENDATIONS, GET_CATEGORIES } from '../../queries';
import UpdateOrderButton from './UpdateOrderButton.js';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { DESTROY_ORDERS, ADD_PRODUCT_TO_ORDER, REMOVE_PRODUCT_FROM_ORDER, UPDATE_ORDER, UPDATE_SHIPPING, UPDATE_MULTIPLE_ORDERS_PRODUCTS, CREATE_BACKORDER, DELETE_BACKORDER, UPDATE_EXISTING_FORMAT, UPDATE_ONE_ORDERS_PRODUCT, CREATE_INFERENCE_MISTAKE } from '../../mutations';
import { PATHS, GREY } from '../../constants';
import propTypes from 'prop-types';
import { useWindowWidth } from '../../Utilities/useWindowWidth';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './orderDetails.less';
import CopyButton from '../../Utilities/CopyButton';
import debounce from 'lodash/debounce';
import { getAssineeList } from '../../Utilities/getAssineeList';
import { closableMessage } from '../../Utilities/closableMessage';
import { useResize } from '../../Utilities/useResize';
import { useStickyState } from '../../Utilities/useStickyState';
import { compareDates } from '../../Utilities/compareDates';
import UploadButton from '../UploadArea/UploadButton';
import consumerWithToken from '../../cable';

// use ws to sync order record

const { TextArea } = Input;

const OrderDetails = ({ location, onAction }) => {
    const { orderId } = useParams();
    const [form] = Form.useForm();
    const [orderUpdated, setOrderUpdated] = useState(false);
    const history = useHistory()
    const [customer, setCustomer] = useState({});
    const [shipping, setShipping] = useState({});
    const [products, setProducts] = useState([]);
    const [boundingBoxes, setBoundingBoxes] = useState([]);
    const [addingItemIndex, setAddingItemIndex] = useState(null);
    const [messagePresent, setMessagePresent] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [allCustomers, setAllCustomers] = useState([]);
    const [isOrderBoundingBoxes, setIsOrderBoundingBoxes] = useState(false);
    const [productSearchValue, setProductSearchValue] = useState('');
    const [productOptions, setProductOptions] = useState([]);
    const [sortedProductOptions, setSortedProductOptions] = useState([]);
    const [orderNumber, setOrderNumber] = useState('');
    const [memoText, setMemoText] = useState('');
    const [backorders, setBackorders] = useState([]);
    const [assigneeList, setAssigneeList] = useState([]);
    const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false);
    const [deleteConfirmLoading, setDeleteConfirmLoading] = useState(false);
    const [showComments, setShowComments] = useStickyState(true, 'showComments');
    const [minimizeFaxView, setMinimizeFaxView] = useStickyState(false, 'minimizeFaxView');
    const [activeTab, setActiveTab] = useStickyState('customer', 'orderDetailsActiveTab')
    const [bulkCopiedIds, setBulkCopiedIds] = useStickyState([], 'bulkCopiedIds');
    const [categories, setCategories] = useState([]);
    const [quantityError, setQuantityError] = useState({});
    const [progress, setProgress] = useState(null);
    const panelRef = useRef(null);
    const quantityInputRef = useRef([]);
    const [uploading, setUploading] = useState(false);
    const [users, setUsers] = useState([]);
    const [firstPageId, setFirstPageId] = useState(null);

    const { initResize, cursor } = useResize(panelRef, {axis: "horizontal", step: 5, minWidth: 400})

    useQuery(GET_CUSTOMERS, {
        onCompleted: (data) => {
            setAllCustomers(data.customers.edges);
        }
    });
    const { data: categoryData, loading: categoryLoading } = useQuery(GET_CATEGORIES);

    useQuery(GET_USER_ONLINE, {
        onCompleted: (data) => {
            setUsers(data.users?.edges.map(({ node }) => {
                return {
                    ...node,
                    value: node.id,
                    label: node.username,
                    username: node.username,
                    id: node.id
                };
            }));
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('currentUser'))?.token;
        const consumer = consumerWithToken(token)
        consumer.subscriptions.create({ channel: 'UsersChannel' }, {
            received: (data) => {
                setUsers(prev => {
                    const newUsers = [...prev];
                    const userIndex = prev.findIndex(user => parseInt(user.id) === parseInt(data.body.id))
                    newUsers[userIndex].online = data.body.online
                    return newUsers
                })
            }
        })
        
        return () => {
            consumer.disconnect()
        }
    }, [])

    const { data: faxesData, refetch: faxesRefetch } = useQuery(GET_ASSIGNEE, {
        fetchPolicy: 'no-cache',
        variables: { activeTab: null },
    });

    const { data: faxList, refetch: faxListRefetch} = useQuery(GET_FAXES, {
        variables: { activeTab: localStorage.getItem('screwdriverOrdersTableActiveTab')?.split('"').join('') || null}
    });

    useEffect(() => {
        if (users && faxesData) {
            setAssigneeList(getAssineeList(users, faxesData))
        }
    }, [users, faxesData])

    const [getCustomer] = useLazyQuery(GET_CUSTOMER, {
        onCompleted: (data) => {
            setCustomer(data.customer);
            if (data.customer.shippings.length === 1) {
                setShipping(data.customer.shippings[0]);
            }
            updateOrder({ variables: { input: { id: orderId, customerId: parseInt(data.customer.id), shippingId: null } } })
        }
    })

    const [getOrdersProducts, {loading: loadingOrdersProducts}] = useLazyQuery(GET_ORDER_PRODUCTS, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            parseProducts(data.order);
        }
    })

    const [updateExistingFormat] = useMutation(UPDATE_EXISTING_FORMAT, {
            onCompleted: () => {
                closableMessage('updateFormatSuccess', 'success', 'フォーマットに新規顧客を追加しました。')
            }
        });

    const [createBackorder] = useMutation(CREATE_BACKORDER, {
        onCompleted: (data) => {
            setBackorders([...backorders, data.createBackorder.backorder]);
        },
        onError: (error) => {
            console.log(error.message)
        }
    });

    const [removeBackorder] = useMutation(DELETE_BACKORDER, {
        onCompleted: () => {
            closableMessage('deleteBackorderSuccess', 'success', '発注番号を削除しました')
        },
        onError: (error) => {
            console.log(error.message)
        }
    });

    const addBackorder = (number) => {
        form.setFieldsValue({ backorder: '' });
        createBackorder({
            variables: {
                input: {
                    orderId: orderId,
                    number: number
                }
            }
        });
    }

    const { data, loading, error } = useQuery(GET_ORDER, {
        fetchPolicy: 'no-cache',
        variables: { orderId: parseInt(orderId) },
        onError: (error) => {
            console.log("GQL ERROR",error)
        }
    });

    const [getProducts, { loading: loadingProducts }] = useLazyQuery(GET_PRODUCTS, {
        onCompleted: (data) => {
            setProductOptions(data.products.edges.map(product => ({
                value: product.node.id,
                label: `${product.node.code} - ${product.node.name}`,
                code: product.node.code,
                name: product.node.name
            })));
        }
    });

    useEffect(() => {
            // reorder the products to go from exact match to partial match
            let exactMatches = []
            let partialMatches = []
            let partialMatchesNoDashes = []
            productOptions.forEach(product => {
                const code = product.code.toLowerCase();
                const name = product.name.toLowerCase();
                const value = productSearchValue.toLowerCase();
                if (code === value || name === value) {
                    exactMatches.push(product);
                } else if (code.includes(value) || name.includes(value)) {
                    partialMatches.push(product);
                } else if (code.replaceAll('-', '').includes(value.replaceAll('-', ''))) {
                    partialMatchesNoDashes.push(product);
                }
            })
            const sortAlpha = arr => arr.sort((a, b) => {
                const aCode = a.code.toLowerCase()
                const bCode = b.code.toLowerCase()
                return (aCode < bCode) ? -1 : (aCode > bCode) ? 1 : 0;
            });
            exactMatches = sortAlpha(exactMatches);
            partialMatches = sortAlpha(partialMatches);
            partialMatchesNoDashes = sortAlpha(partialMatchesNoDashes);

            const reorderedProducts = [...exactMatches, ...partialMatches, ...partialMatchesNoDashes]
            setSortedProductOptions(reorderedProducts);
    }, [productOptions, productSearchValue])

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            if ( value.match(/[ァ-ン]/)) {
                message.error('全角カタカナは入力できません。')
                return
            }
            setProductOptions([]);
            setProductSearchValue(value);
            getProducts({ variables: { search: value } });
        };

        return debounce(loadOptions, 800);
    }, [getProducts]);

    const screenWidth = useWindowWidth();

    const [addProductToOrder] = useMutation(ADD_PRODUCT_TO_ORDER, {
        onError: error => console.log(error),
        onCompleted: () => {
            getOrdersProducts({ variables: { orderId: parseInt(orderId) } });
            message.success('商品を追加しました。');
        }
    });

    const [removeProductFromOrder] = useMutation(REMOVE_PRODUCT_FROM_ORDER, {
        onError: error => console.log(error)
    });

    const [reportMistake] = useMutation(CREATE_INFERENCE_MISTAKE)

    const removeProduct = (variableObj) => {
        removeProductFromOrder(variableObj)
    }

    const [updateOneOrdersProduct] = useMutation(UPDATE_ONE_ORDERS_PRODUCT, {
        onCompleted: () => {
            getOrdersProducts({ variables: { orderId: parseInt(orderId) } });
        }
    })


    const [updateMultipleOrdersProducts] = useMutation(UPDATE_MULTIPLE_ORDERS_PRODUCTS, {
        onError: error => console.log(error)
    });

    const [updateOrder] = useMutation(UPDATE_ORDER, {
        onCompleted: () => {
            faxesRefetch();
        },
        onError: (error) => {
            closableMessage('updateOrderError', 'error', error.message, 0);
        }
    });

    const [updateOrderMemo] = useMutation(UPDATE_ORDER, {
        onError: (error) => {
            error.graphQLErrors.forEach((e) => {
                closableMessage('updateMemoError', 'error', e?.message, 0);
            });
        },
        onCompleted: () => {
            closableMessage('updateMemoSuccess', 'success', "メモを保存しました。", 3);
        },
    });

    const [updateOrderNumber] = useMutation(UPDATE_ORDER, {
        onError: (error) => {
            error.graphQLErrors.forEach((e) => {
                closableMessage('updateOrderNumberError', 'error', e?.message, 0);
            });
        },
        onCompleted: () => {
            closableMessage('updateOrderNumberSuccess', 'success', "伝票番号を保存しました。", 3);
        },
    });

    const [updateShipping] = useMutation(UPDATE_SHIPPING, {
        onError: error => console.log(error)
    });

    const [deleteOrders] = useMutation(DESTROY_ORDERS, {
        onCompleted: () => {
            setDeleteConfirmLoading(false);
            closableMessage('deleteOrderSuccess', 'success', 'PDFを削除しました。')
            history.push('/orders');
        },
        onError: () => {
            closableMessage('deleteOrderError', 'error', 'PDFの削除に失敗しました。');
        }
    });

    const parseProducts = (order) => {
        const newProducts = order.products.map(product => {
            const op = order.ordersProducts?.find(orderProduct => orderProduct.productId === product.id)
            return ({
                ...product,
                displayOrder: op?.displayOrder,
                ordersProductId: op?.id,
                quantity: op?.quantity,
                quantity_confidence: op?.quantityConfidence,
                confidence: op?.confidence,
                isSelected: op?.isSelected
            })
        }
        ).sort((a, b) => {
            if (!a.displayOrder) {
                return 1;
            } else if (!b.displayOrder) {
                return -1;
            }
            return a.displayOrder - b.displayOrder
        });
                setProducts(products => [
            ...newProducts
        ]);
    }

    useEffect(() => form.resetFields(), [products, customer, form]);
    useEffect(() => {
        if (!loading && !error) {
            const customersSortedByLatitude = data.order.orderType?.customers.sort((a, b) => b.latitude - a.latitude);
            setCustomerList(customersSortedByLatitude);
            setProgress(data.order.fax?.progress);
            if (data.order.customer) {
                setCustomer(data.order.customer);
                if (data.order.customer.shippings.length === 1) {
                    setShipping(data.order.customer.shippings[0]);
                }
            } else if (data.order.orderType?.customers.length === 1) {
                setCustomer(data.order.orderType.customers[0]);
            }

            setBoundingBoxes(data.order.boundingBoxes || data.order.orderType?.boundingBoxes);
            setIsOrderBoundingBoxes(data.order.boundingBoxes ? true : false);
            setOrderNumber(data.order.orderNumber);
            const memoContent = data.order.fax?.memo
            setMemoText(memoContent ? memoContent : '');
            setBackorders(data.order.backorders);
            setFirstPageId(data.order.fax?.firstPageId);
        }
    }, [data, loading, error]);

    useEffect(() => {
        if (!loading && !error && data.order.products) {
            parseProducts(data.order)
        }

    }, [data, loading, error])

    useEffect(() => {
        if (data?.order?.shipping && customer?.shippings) {
            setShipping(
                customer.shippings.find(shipping => shipping.id === data.order.shipping.id) || {}
            );
        }
    }, [data, customer]);

    useEffect(() => {
        if (categoryData?.categories) {
            setCategories(categoryData.categories.edges.sort((a, b) => a.node.sortOrder - b.node.sortOrder)
                .map(({ node }, index) => {
                    return {
                        value: node.id,
                        label: node.displayName,
                        id: node.id
                    };
            }));
        }
    }, [categoryData, categoryLoading]);

    const handleDragEnd = result => {
        if (!result.destination) return;
        let items = Array.from(products);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        items = items.map((item, index) => ({
            ...item,
            display_order: index + 1
        }));
        setProducts(items);
        updateMultipleOrdersProducts({ variables: { input: { orderId: parseInt(orderId), products: items } }});
    }

    const handleDeleteOrder = () => {
        setDeleteConfirmLoading(true);
        deleteOrders({ variables: { input: { ids: [orderId] } } });
    }

    const handleOrderNumberChange = (e) => {
        const { value } = e.target;
        setOrderNumber(value);
    };

    const saveOrderNumber = () => {
        updateOrderNumber({ variables: { input: { id: orderId, orderNumber: orderNumber || null } } })
    };

    const handleMemoTextChange = (e) => {
        const { value } = e.target;
        setMemoText(value);
    };

    const saveMemo = () => {
        updateOrderMemo({ variables: { input: { id: orderId, memo: memoText || null } } })
    };

    const [productRecommendations, setProductRecommendations] = useState([]);

    const {loading: recsLoading} = useQuery(GET_PRODUCT_RECOMMENDATIONS, {
        variables: { customerId: parseInt(customer?.id) },
        onCompleted: (data) => {
            const mapped = [...data.productRecommendations].sort((a, b) => {
                if (a.product.code < b.product.code) {
                    return -1;
                } else if (a.product.code > b.product.code) {
                    return 1;
                } else {
                    return 0;
                }
            }).map((product) => {
                return { ...product.product, key: product.id, timestamp: product.createdAt, confidence: product.confidence }
            })
            setProductRecommendations(mapped);
        }
    });

    const recommendation_columns = [
        {
            title: <Typography.Text strong>品番</Typography.Text>,
            dataIndex: 'code',
        },
        {
            title: <Typography.Text strong>商品名</Typography.Text>,
            dataIndex: 'name',
            ellipsis: true
        },
        {
            title: <Typography.Text strong>信頼度</Typography.Text>,
            dataIndex: 'confidence',
            render: (text) => parseFloat(text).toFixed(3),
        },
        {
            render: (record) => (
                <CopyButton text={record.code} />
            ),
            width: 50
        }
    ]

    if (loading) { return <Spin/>; }

    const clusterName = data?.order?.orderType?.name || 'N/A';
    const categoryName = data?.order?.category?.displayName || 'N/A';

    if (!messagePresent && location && location.state && location.state.updated) {
        closableMessage('success', 'success', location.state.updated);
        setMessagePresent(true);
    }

    const copyAllProducts = () => {
        // copy all products to clipboard, 1 per line
        const productsString = products.map(product => `${product.code}\n${product.quantity || 0}`).join('\n');
        navigator.clipboard.writeText(productsString);
        setBulkCopiedIds(prev => {
            const newIds = [...prev];
            newIds.unshift(orderId);
            return newIds.slice(0, 50); // only keep last 50
        })
        closableMessage('success', 'success', 'すべての商品をコピーしました。');
    }

    const copySelectedProducts = () => {
        // copy selected products to clipboard, 1 per line
        const productsString = products.filter(product => product.isSelected).map(product => `${product.code}\n${product.quantity || 0}`).join('\n');
        navigator.clipboard.writeText(productsString);
        setBulkCopiedIds(prev => {
            const newIds = [...prev];
            newIds.unshift(orderId);
            return newIds.slice(0, 50); // only keep last 50
        })
        closableMessage('success', 'success', '選択した商品をコピーしました。');
    }

    const navigateOrders = (direction) => {
        const orderIdList = localStorage.getItem('screwdriverOrderListIds') ? JSON.parse(localStorage.getItem('screwdriverOrderListIds')) :
            faxList.recentFaxes.sort((a, b) => {
            if (a.createdAt === b.createdAt) {
                return a.s3ObjectKey.localeCompare(b.s3ObjectKey);
            }
            return new Date(b.createdAt) - new Date(a.createdAt);
        }).map(fax => fax.firstPageId);
        const targetIndexDiff = direction === 'next' ? 1 : -1;
        const targetOrder = orderIdList[orderIdList.indexOf(parseInt(firstPageId)) + targetIndexDiff];
        if (targetOrder) {
            localStorage.removeItem('orderDetailsActiveTab');
            setActiveTab('customer');
            history.push(`${PATHS.orders}/${targetOrder}`);
        } else {
            closableMessage('info', 'info', targetIndexDiff === 1 ? '最後の受注です。' : '最初の受注です。');
        }
    }

    if (!error) { return <>
        { orderUpdated &&
            <>
                <Col span={24}>
                    <Alert message="間違いデータに追加されました。" type="success" showIcon closable/>
                </Col>
                <Divider />
                <Redirect to={`${orderUpdated}`} />
            </>
        }
        { data?.order?.isQuote &&
            <Col span={24}>
                <Alert
                    message="このPDFは見積もりです。"
                    type='warning'
                    closable
                    style={{ marginBottom: '24px' }}
                />
            </Col>
        }
        <Row justify='space-between'>
            <Col style={{display: 'flex'}}>
                <Typography.Title data-testid='title' level={5} style={{ alignSelf: 'center', marginBottom: 12}}>
                        データ名: { data.order.name }
                </Typography.Title>
            </Col>
            <Col>
                <UploadButton uploading={uploading} setUploading={setUploading} showMessage={true} faxListRefetch={faxListRefetch}/>
            </Col>
        </Row>
        <Row justify='space-between'>
            <Col>
                <Space>
                    カテゴリー:
                    <Select
                        placeholder='選択してください'
                        style={{ width: 130 }}
                        allowClear
                        showSearch
                        defaultValue={categoryName}
                        onChange={e => {
                            updateOrder({ variables: { input: { id: orderId, categoryId: e || null } } })
                        }}
                    >
                        { categories?.length > 0 && categories.map((category, idx) =>
                                <Select.Option
                                    key={idx}
                                    value={category.id}
                                >
                                    { category.label }
                                </Select.Option>
                            )
                        }
                    </Select>
                    担当者:
                    <Select
                        placeholder='選択してください'
                        style={{ width: 165 }}
                        allowClear
                        showSearch
                        defaultValue={data?.order?.assignee?.id}
                        onChange={e => {
                            updateOrder({ variables: { input: { id: orderId, assigneeId: e || null } } })
                        }}
                    >
                        { assigneeList?.length > 0 && assigneeList.map((assignee, idx) =>
                                <Select.Option
                                    key={idx}
                                    value={assignee.id}
                                    style={{ backgroundColor:
                                                assignee.online
                                                ? "#FEFEB6"
                                                : assignee.employmentType === 'hold'
                                                ? "lightgray"
                                                :  "" }}
                                >
                                    <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <Typography.Text strong>
                                            {assignee.username}
                                        </Typography.Text>
                                        <Typography.Text strong>
                                            {assignee.count || '0'}
                                        </Typography.Text>
                                    </Space>
                                </Select.Option>
                            )
                        }
                    </Select>
                    伝票番号:
                    <Input
                        data-testid='order-number-input'
                        placeholder='伝票番号'
                        value={orderNumber}
                        onChange={handleOrderNumberChange}
                        style={{ width: 100 }}
                    />
                    <Button data-testid='order-number-save-button' onClick={saveOrderNumber}>保存</Button>
                    <Divider type='vertical' />
                    <UpdateOrderButton
                        id={parseInt(orderId)}
                        text={'アップロード済み'}
                        attribute={'uploaded'}
                        value={true}
                        handleCompleted={ () => {
                            setOrderUpdated(PATHS.orders);
                            onAction();
                            closableMessage('success', 'success', 'アップロード済みタブに保存しました');
                        } }
                    />
                    <UpdateOrderButton
                        id={parseInt(orderId)}
                        text={'未完了'}
                        attribute={'incomplete'}
                        value={true}
                        handleCompleted={ () => {
                            setOrderUpdated(PATHS.orders);
                            onAction();
                            closableMessage('success', 'success', '未完了タブに保存しました');
                        } }
                    />
                    <UpdateOrderButton
                        id={parseInt(orderId)}
                        text={'不足・取寄'}
                        attribute={'backordered'}
                        value={true}
                        handleCompleted={ () => {
                            setOrderUpdated(PATHS.orders);
                            onAction();
                            closableMessage('success', 'success', '不足・取寄タブに保存しました');
                        } }
                    />
                </Space>
                <Space style={{marginLeft: '.5rem'}}>
                    <Divider type='vertical' />
                    <Popconfirm
                        title='削除してよろしいですか。'
                        cancelText='キャンセル'
                        okText='削除する'
                        visible={deletePopConfirmVisible}
                        onConfirm={() => handleDeleteOrder()}
                        okButtonProps={{ loading: deleteConfirmLoading, danger: true }}
                        onCancel={() => setDeletePopConfirmVisible(false)}
                        placement='topRight'
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                        <Button
                            onClick={() => setDeletePopConfirmVisible(true)}
                            icon={<DeleteOutlined/>}>
                            削除
                        </Button>
                    </Popconfirm>
                </Space>
                <Space>
                    <Divider type='vertical' />
                    <Button
                        onClick={() => {
                            setMinimizeFaxView(prev => !prev)
                        }}
                        icon={minimizeFaxView ? <PlusCircleOutlined /> : <MinusCircleOutlined />}
                    >
                        {minimizeFaxView ? 'FAXエリア拡大' : 'FAXエリア縮小'}
                    </Button>
                </Space>
                <Space style={{marginLeft: '.5rem'}}>
                    <Button
                        type="text"
                        disabled={uploading}
                        icon={<LeftOutlined
                            onClick={() => navigateOrders('prev')}
                        />}
                    />
                    <Button
                        type="text"
                        disabled={uploading}
                        icon={<RightOutlined
                            onClick={() => navigateOrders('next')}
                        />}
                    />
                </Space>
            </Col>
        </Row>
        <Divider/>
        <div style={{display: "flex"}}>
            <div ref={panelRef} style={{display: "flex", width: minimizeFaxView ? "90%" : "33%"}}>
                <Form form={form}
                    labelCol={ { span: 22 } }
                    wrapperCol={ { span: 22 } }
                    labelAlign='left'
                    layout='vertical'
                    colon={false}
                    name="saveOrder"
                    style={{width: '100%'}}
                >
                    <Tabs activeKey={activeTab} onChange={setActiveTab}>
                        <Tabs.TabPane tab='得意先' key='customer'>
                        { progress !== 'finished' ? <Alert message="読み込みが未完了です。" type='warning' showIcon/> :
                            <>
                                <Form.Item
                                    name="customer"
                                    label="得意先"
                                >
                                    <Select
                                        data-testid='customer-select'
                                        showSearch
                                        allowClear
                                        placeholder={'得意先名を入力して選択してください。'}
                                        onSelect={(customerId) => {
                                            const c = data.order.orderType.customers.find(customer => customer.id === customerId) || allCustomers.find(customer => customer.node.id === customerId).node
                                            if (!c.shippings) { // if non-format customer
                                                getCustomer({ variables: { id: parseInt(c.id) } })
                                                updateExistingFormat({ variables: { input: { id: data.order.orderType?.id, customerId: parseInt(customerId) } } })
                                            } else {
                                                setCustomer(c);
                                                updateOrder({ variables: { input: { id: orderId, customerId: parseInt(customerId), shippingId: null } } })
                                            }
                                            setShipping({})
                                        }}
                                        onClear={() => {
                                            setCustomer({})
                                            updateOrder({ variables: { input: { id: orderId, customerId: null, shippingId: null } } })
                                            setShipping(undefined)
                                        }}
                                        filterOption={(input, option) =>{
                                            if(option.children){
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                            }else if(option.label){
                                                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                            }
                                        }}
                                    >
                                        <Select.OptGroup label='書式に紐付く得意先'>
                                            { customerList?.length > 0 &&
                                                customerList.map(
                                                    (customer, idx) =>
                                                        <Select.Option key={idx} value={customer.id}>
                                                            { `${customer.nameOne} ${customer.nameTwo || ''}` }
                                                        </Select.Option>
                                                )
                                            }
                                        </Select.OptGroup>
                                        <Select.OptGroup label='マスターデータ'>
                                            { allCustomers && allCustomers.map(({ node: customer}) => {
                                                return <Select.Option key={customer.code + 'allCustomers'} value={customer.id}>
                                                    { `${customer.nameOne} ${customer.nameTwo || ''}` }
                                                </Select.Option>
                                            })}
                                        </Select.OptGroup>
                                    </Select>
                                </Form.Item>
                                { customer.code &&
                                    <>
                                        <Form.Item
                                            name="customer"
                                            label="得意先名"
                                            initialValue={ `${customer.nameOne} ${customer.nameTwo || ''}` }
                                        >
                                            <Input suffix={
                                                <CopyButton text={`${customer.nameOne} ${customer.nameTwo || ''}`}/>
                                            }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="customerCode"
                                            label="得意先コード①"
                                            initialValue={customer.code.slice(0, -2)}
                                        >
                                            <Input suffix={
                                                <CopyButton text={customer.code.slice(0, -2)}/>}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="officeCode"
                                            label="得意先コード②"
                                            initialValue={customer.code.slice(-2)}
                                        >
                                            <Input suffix={
                                                <CopyButton text={customer.code.slice(-2)}/>}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="kananame"
                                            label="カナ名"
                                            initialValue={customer.kanaName}
                                        >
                                            <Input suffix={
                                                <CopyButton text={customer.kanaName}/>}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="zipcode"
                                            label="郵便番号"
                                            initialValue={customer.zipcode}
                                        >
                                            <Input suffix={
                                                <CopyButton text={customer.zipcode}/>}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="address"
                                            label="住所"
                                            initialValue={customer.address}
                                        >
                                            <Input suffix={
                                                <CopyButton text={customer.address}/>}
                                            />
                                        </Form.Item>
                                        <Row>
                                            <Col span="12">
                                                <Form.Item
                                                    name="phone"
                                                    label="TEL"
                                                    initialValue={customer.phone}
                                                >
                                                    <Input suffix={
                                                        <CopyButton text={customer.phone}/>}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span="11">
                                                <Form.Item
                                                    name="fax"
                                                    label="FAX"
                                                    initialValue={customer.fax}
                                                >
                                                    <Input suffix={
                                                        <CopyButton text={customer.fax}/>}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            name="memo"
                                            label="得意先備考"
                                            initialValue={customer.memo}
                                        >
                                            <TextArea
                                                suffix={
                                                    <CopyButton text={customer.memo}/>
                                                }
                                            />
                                        </Form.Item>
                                    </>
                                }
                            </>
                        }
                        </Tabs.TabPane>
                        {shipping &&
                            <Tabs.TabPane tab='納品先' key='shipping'>
                                { progress === 'uploaded' ? <Alert message="読み込みが未完了です。" type='warning' showIcon/> :
                                    <>
                                        <Form.Item
                                            name="shipping"
                                            label="納品先"
                                            initialValue={shipping.id}
                                        >
                                            <Select
                                                data-testid='shipping-select'
                                                allowClear
                                                showSearch
                                                placeholder={'納品先名を入力して選択してください。'}
                                                onSelect={(shippingId) => {
                                                    setShipping(
                                                        customer.code &&
                                                        customer.shippings.find(shipping => shipping.id === shippingId)
                                                    );
                                                    const currentDateTime = new Date();
                                                    updateOrder({ variables: { input: { id: orderId, shippingId } } });
                                                    updateShipping({ variables: { input: { id: shippingId, lastSelectedAt: currentDateTime } } })
                                                }}
                                                onClear={() => {
                                                    setShipping({});
                                                    updateOrder({ variables: { input: { id: orderId, shippingId: null } } });
                                                }}
                                                filterOption={(input, option) => {
                                                    const chunks = input.split(' ').map(e => e.toLowerCase())
                                                    const name = option.props.children.props.children.toLowerCase() // thank you antd
                                                    const title = option.props.children.props.title.toLowerCase()
                                                    return chunks.every(chunk => name.includes(chunk) || title.includes(chunk))
                                                }}
                                            >
                                                { customer.code && customer.shippings?.sort((a, b) => {
                                                    return a.kanaName.localeCompare(b.kanaName, 'ja')
                                                            || compareDates(a.lastSelectedAt, b.lastSelectedAt);
                                                }).map(
                                                    shipping =>
                                                        <Select.Option key={shipping.id} value={shipping.id}>
                                                            <Tooltip title={shipping.address + ' - ' + shipping.zipcode + ' - ' + shipping.phone + ' - ' + shipping.kanaName} placement="right">
                                                            { `${shipping.nameOne} ${shipping.nameTwo || ''}` }
                                                            </Tooltip>
                                                        </Select.Option>
                                                )
                                                }
                                            </Select>
                                        </Form.Item>
                                        { shipping.id &&
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography.Text>納品先名</Typography.Text>
                                                <Input
                                                    value={`${shipping.nameOne} ${shipping.nameTwo || ''}`}
                                                    className='shipping-input'
                                                    suffix={
                                                        <CopyButton text={`${shipping.nameOne} ${shipping.nameTwo || ''}`}/>
                                                    }
                                                />
                                                <Typography.Text>カナ名</Typography.Text>
                                                <Input
                                                    value={ shipping.kanaName }
                                                    className='shipping-input'
                                                    suffix={
                                                        <CopyButton text={shipping.kanaName}/>
                                                    }
                                                />
                                                <Typography.Text>郵便番号</Typography.Text>
                                                <Input
                                                    value={ shipping.zipcode }
                                                    className='shipping-input'
                                                    suffix={
                                                        <CopyButton text={shipping.zipcode}/>
                                                    }
                                                />
                                                <Typography.Text>住所</Typography.Text>
                                                <Input
                                                    value={ shipping.address }
                                                    className='shipping-input'
                                                    suffix={
                                                        <CopyButton text={shipping.address}/>
                                                    }
                                                />
                                                <Row>
                                                    <Col span="12" style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography.Text>TEL</Typography.Text>
                                                        <Input
                                                            value={ shipping.phone }
                                                            className='shipping-input'
                                                            suffix={
                                                                <CopyButton text={shipping.phone}/>
                                                            }
                                                        />
                                                    </Col>
                                                    <Col span="11" style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography.Text>FAX</Typography.Text>
                                                        <Input
                                                            value={ shipping.fax }
                                                            className='shipping-input'
                                                            suffix={
                                                                <CopyButton text={shipping.fax}/>
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </>
                                }
                            </Tabs.TabPane>
                        }
                        <Tabs.TabPane tab='商品内容' key='product'>
                            { progress === 'uploaded' ? <Alert message="読み込みが未完了です。" type='warning' showIcon/> :
                                <>
                                    <Button onClick={() => setShowComments(prev => !prev)}>{showComments ? "備考非表示" : "備考表示"}</Button>
                                    <DragDropContext key="current-page-items" onDragEnd={res => handleDragEnd(res)}>
                                        <Droppable droppableId="products">
                                            {(provided) => (
                                                <div className='products' {...provided.droppableProps} ref={provided.innerRef} >
                                                    { addingItemIndex !== -1 ? <Divider className="add-product-divider" data-testid="add-product-button" dashed style={{margin: ".25rem 0", paddingRight: "3.5rem"}}>
                                                        <PlusCircleOutlined
                                                            className="copy-icon"
                                                            onClick={() => {
                                                                setAddingItemIndex(-1);
                                                            }}
                                                        />
                                                    </Divider>
                                                    :
                                                    <Form.Item
                                                        label="商品"
                                                    >
                                                        <Select
                                                            showSearch
                                                            filterOption={false}
                                                            key={'selectProduct'}
                                                            placeholder={'商品コードで検索'}
                                                            onSearch={debounceFetcher}
                                                            notFoundContent={loadingProducts ? <Spin size="small" /> : null}
                                                            options={sortedProductOptions}
                                                            onSelect={productId => {
                                                                addProductToOrder({
                                                                    variables: {
                                                                        input: {
                                                                            orderId: data.order.id,
                                                                            productId,
                                                                            displayOrder: 1
                                                                        }
                                                                    }
                                                                });
                                                                reportMistake({ variables: { input: {correctProductId: productId, orderId: data.order.id} }})
                                                                setAddingItemIndex(false);
                                                            }}
                                                            autoFocus={true}
                                                        />
                                                    </Form.Item>}
                                                { products && products.map((product, index) => {
                                                    let tooltip = ''
                                                    if (product.comments) {
                                                        tooltip = "備考: " + product.comments
                                                    }
                                                    return (
                                                    <Draggable key={`stockItem${product.id}`} draggableId={product.id} index={index}>
                                                        {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                        <div style={{display: "flex", margin: ".25rem 0", marginRight: "3.5rem"}}>
                                                            <div style={{display: "flex", width: "100%"}}>
                                                                <Checkbox 
                                                                    checked={product.isSelected}
                                                                    className='product-checkbox'
                                                                    onClick={() => {
                                                                        updateOneOrdersProduct({ variables: { input: { id: product.ordersProductId, isSelected: !product.isSelected } } })
                                                                    }}
                                                                />
                                                                <Tooltip title='取り消し'>
                                                                    <Button icon={<MinusCircleOutlined/>}
                                                                        onClick={() => {
                                                                            removeProduct({
                                                                                variables: {
                                                                                    input: {
                                                                                        orderId: data.order.id,
                                                                                        productId: product.id
                                                                                    }
                                                                                }
                                                                            });
                                                                            setProducts(products.filter(p => {
                                                                                return p.id !== product.id;
                                                                            }));
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                                <Select
                                                                    style={{ color: product.confidence ? 'black' : 'blue', width: "80%", height: "2rem" }}
                                                                    className={!product.isSelected ? 'not-selected' : ''}
                                                                    showSearch
                                                                    defaultValue={
                                                                        `${product.code} - ${product.name}`
                                                                    }
                                                                    filterOption={false}
                                                                    placeholder={'商品名またはコードを入力してください。'}
                                                                    onSearch={debounceFetcher}
                                                                    notFoundContent={loadingProducts ? <Spin size="small" /> : null}
                                                                    options={sortedProductOptions}
                                                                    onChange={v => updateOneOrdersProduct({ variables: { input: { id: product.ordersProductId, productId: v, confidence: null } } }).then(() => message.success('商品を追加しました。'))}    
                                                                />
                                                                <Space direction='vertical' size={0} style={{width: "20%"}}>
                                                                    <Input
                                                                        key={product.ordersProductId}
                                                                        ref={(ref) => quantityInputRef.current[index] = ref}
                                                                        onPressEnter={e => {
                                                                            e.preventDefault()
                                                                            if (quantityInputRef.current[index + 1]) {
                                                                                quantityInputRef.current[index + 1].focus();
                                                                                quantityInputRef.current[index + 1].select();
                                                                            } else {
                                                                                quantityInputRef.current[index].blur();
                                                                            }
                                                                        }}
                                                                        type={'number'}
                                                                        style={{borderColor: quantityError[`${data.order.pageNum}-${product.id}`] || product.stock < product.quantity ? 'red' : ''}}
                                                                        defaultValue={product.quantity}
                                                                        suffix={product.productUnit?.name}
                                                                        onBlur={e => {
                                                                            if (parseInt(e.target.value) > (product.stock || 0) ) {
                                                                                setQuantityError({...quantityError, [`${data.order.pageNum}-${product.id}`]: true})
                                                                            } else {
                                                                                setQuantityError({...quantityError, [`${data.order.pageNum}-${product.id}`]: false})
                                                                            }
                                                                            updateOneOrdersProduct({ variables: { input: { id: product.ordersProductId, quantity: parseInt(e.target.value), quantityConfidence: null } } }).then(() => message.success('数量を更新しました。'))
                                                                        }}
                                                                        min={0}
                                                                        className='hide-number-arrows'
                                                                    />
                                                                    <Tag data-testid='product-option' color={ product.stock && product.stock > 0 ? 'success' : 'error' } style={{alignSelf: 'self-end'}}>
                                                                        在庫 { product.stock || 0 }{ product.productUnit?.name }
                                                                    </Tag>
                                                                </Space>
                                                                <CopyButton buttonMode={true} text={product.code}/>
                                                            </div>
                                                        </div>
                                                        {(product.comments) && showComments &&
                                                            <Input.TextArea style={{maxWidth: "calc(100% - 3.5rem)"}} value={tooltip}/>
                                                        }
                                                        <div style={{marginRight: "3.5rem"}}>
                                                            { addingItemIndex !== index ?
                                                            <Divider className="add-product-divider" data-testid="add-product-button" dashed style={{margin: ".25rem 0"}}>
                                                                <PlusCircleOutlined
                                                                    className="copy-icon"
                                                                    onClick={() => {
                                                                        setAddingItemIndex(index);
                                                                    }}
                                                                />
                                                            </Divider>
                                                            :
                                                            <Form.Item
                                                                label="商品"
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    filterOption={false}
                                                                    key={'selectProduct'}
                                                                    placeholder={'商品コードで検索'}
                                                                    onSearch={debounceFetcher}
                                                                    notFoundContent={loadingProducts ? <Spin size="small" /> : null}
                                                                    options={sortedProductOptions}
                                                                    onSelect={productId => {
                                                                        addProductToOrder({
                                                                            variables: {
                                                                                input: {
                                                                                    orderId: data.order.id,
                                                                                    productId,
                                                                                    displayOrder: product.displayOrder + 1
                                                                                }
                                                                            }
                                                                        });
                                                                        reportMistake({ variables: { input: {correctProductId: productId, orderId: data.order.id} }})
                                                                        setAddingItemIndex(false);
                                                                    }}
                                                                    autoFocus={true}
                                                                />
                                                            </Form.Item>}
                                                        </div>
                                                    </div>
                                            )}
                                            </Draggable>
                                                )})}
                                                {provided.placeholder}
                                            </div>)}
                                        </Droppable>
                                        { loadingOrdersProducts && <Spin size="small" style={{marginLeft: "40%", marginBottom: "5%"}}/> }
                                    </DragDropContext>
                                    { products?.length > 0 && 
                                        <Button 
                                            style={{maxWidth: "calc(100% - 3.5rem)"}} 
                                            type="dashed" 
                                            onClick={() => products.filter(p => p.isSelected).length > 0 ? copySelectedProducts() : copyAllProducts()}
                                            block 
                                            icon={bulkCopiedIds.includes(orderId) ? <CheckOutlined/> : <CopyOutlined />}
                                        />
                                    }
                                    { customer?.id && <>
                                        {!recsLoading ?
                                            <Table style={{marginTop: "10rem", marginRight: "1rem"}} title={() => <Typography>オススメ商品</Typography>} columns={recommendation_columns} dataSource={productRecommendations} /> : <Spin className="spin-center"/>}
                                        </>
                                    }
                                </>
                            }
                        </Tabs.TabPane>
                        <Tabs.TabPane tab='発注番号' key='backorder'>
                        <div className='products'>
                            { backorders && backorders.map((backorder) => {
                                return (
                                    <div style={{display: "flex"}} key={backorder.id}>
                                        <Form.Item
                                            name={backorder.id}
                                            initialValue={backorder.number}
                                            style={{width: "100%"}}
                                            >
                                            <Input value={backorder.number} suffix={
                                                <CopyButton text={backorder.number}/>
                                            }
                                            addonAfter={
                                                <Tooltip title='取り消し'>
                                                    <MinusCircleOutlined
                                                        onClick={() => {
                                                            removeBackorder({
                                                                variables: {
                                                                    input: {
                                                                        backorderId: backorder.id
                                                                    }
                                                                }
                                                            });
                                                            setBackorders(backorders.filter(b => {
                                                                return b.id !== backorder.id;
                                                            }));
                                                        }}
                                                        className='minus-icon'
                                                        />
                                                </Tooltip>
                                            }
                                            />
                                        </Form.Item>
                                </div>)})}
                                <Form.Item name="backorder" style={{width: "100%"}}>
                                    <Input
                                        data-testid='backorder-input'
                                        placeholder='発注番号を入力してください。'
                                        onPressEnter={e => addBackorder(e.target.value)}
                                        suffix={<Button data-testid='backorder-save-button' onClick={e => addBackorder(form.getFieldValue('backorder'))} type="primary">OK</Button>}
                                    />
                                </Form.Item>
                            </div>

                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<span>{memoText?.length > 0 && <BulbOutlined style={{marginRight: ".25rem"}}/>}メモ</span>} key='memo'>
                            <TextArea
                                data-testid='memo-input'
                                style={{
                                    width: '95%'
                                }}
                                rows={6}
                                value={memoText}
                                onChange={handleMemoTextChange}
                                placeholder='ここに入力してください。'
                                onBlur={saveMemo}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
                <div style={{width: "5px", height: "100%", cursor: "ew-resize"}} cursor={cursor} onMouseDown={initResize} />
            </div>
            <div style={{ backgroundColor: GREY, marginTop: '-24px', flexGrow: "1" }}>
                { !loading && data.order.presignedDownloadUrl &&
                    <>
                        <OrderPdf siblings={data.order?.fax?.orders} showHideButton pageWidth={minimizeFaxView ? screenWidth * 0.20 : screenWidth * 0.5} file={data.order.presignedDownloadUrl}
                            unmodifiable boundingBoxEnabled={boundingBoxes && boundingBoxes.length > 0}
                            rotation={data.order.rotation}
                            rotationButtons
                            orderTypeDimensions={ data.order.orderType && { width: data.order.orderType.width, height: data.order.orderType.height }}
                            annotationData={boundingBoxes} initialPageNum={data.order.pageNum}
                            isOrderBoundingBoxes={isOrderBoundingBoxes} scale={2}
                            orderId={data.order.id}
                        />
                        { data.order.orderType &&
                        <>
                            <br/>
                            <Typography.Text className='ant-row ant-row-center' strong>
                                フォーマットID : { clusterName }
                            </Typography.Text>
                            <br/>
                            <OrderPdf
                                pageWidth={minimizeFaxView ? screenWidth * 0.1 : screenWidth * 0.35}
                                file={data.order.orderType.samplePresignedDownloadUrl}
                            />
                        </>
                        }
                    </>
                }
            </div>
        </div>
    </>
    }
    else return <div style={{display: 'flex', flexDirection: 'column', alignItems: "center"}}>
        <Typography.Text strong style={{marginBottom: ".5rem"}}>
        データがありません。
        </Typography.Text>
        <Button onClick={e => history.push(PATHS.orders)}>全てのデータ</Button>
    </div>;
};

OrderDetails.propTypes = {
    onAction: propTypes.func,
    location: propTypes.object
};

export default withRouter(OrderDetails);
