import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { DESTROY_EMAIL_ORDERS } from '../../mutations';
import propTypes from 'prop-types';
import { closableMessage } from '../../Utilities/closableMessage';

const DeleteEmailsButton = ({ selectedOrderIds, handleCompleted }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [deleteEmails] = useMutation(DESTROY_EMAIL_ORDERS, {
        onCompleted: () => handleCompleted(),
        onError: (error) => {
            console.log(error);
        }
    });

    const showPopconfirm = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        deleteEmails({ variables: { input: { ids: selectedOrderIds } } })
        .then(() => {
            setConfirmLoading(false);
            setVisible(false);
            closableMessage('deleteEmailSuccess', 'success', '選択したPDFを削除しました。');
        })
        .catch(() => {
            setConfirmLoading(false);
            setVisible(false);
            closableMessage('deleteEmailError', 'error', '選択したPDFを削除できませんでした。', 0)
        });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <Popconfirm
            title='選択したPDFを削除してよろしいですか。'
            cancelText='キャンセル'
            okText='削除する'
            visible={visible}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading, danger: true }}
            onCancel={handleCancel}
            placement='topRight'
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
            <Button
                onClick={showPopconfirm}
                icon={<DeleteOutlined/>}>
                削除
            </Button>
        </Popconfirm>
    );
};

DeleteEmailsButton.propTypes = {
    handleCompleted: propTypes.func,
    selectedOrderIds: propTypes.array
};

export default DeleteEmailsButton;
