import React, { useState, useEffect, useRef, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
    GET_EMAIL,
    GET_CUSTOMERS,
    GET_CUSTOMER,
    GET_PRODUCTS,
    GET_EMAIL_ORDERS_PRODUCTS,
    GET_PRODUCT_RECOMMENDATIONS,
    GET_CATEGORIES,
    GET_EMAIL_ASSIGNEE,
    GET_USER_ONLINE,
} from "../../queries";
import {
    DESTROY_EMAIL_ORDERS,
    REMOVE_PRODUCT_FROM_EMAIL_ORDER,
    UPDATE_EMAIL_ORDER,
    UPDATE_SHIPPING,
    UPDATE_MULTIPLE_EMAIL_ORDERS_PRODUCTS,
    ADD_PRODUCT_TO_EMAIL_ORDER,
    UPDATE_ONE_EMAIL_ORDERS_PRODUCT,
    CREATE_EMAIL_BACKORDER,
    DELETE_EMAIL_BACKORDER,
} from "../../mutations";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    Table,
    Tag,
    Space,
    Checkbox,
    Tabs,
    Spin,
    Divider,
    message,
    Typography,
    Popconfirm,
    Form,
    Input,
    Row,
    Col,
    Select,
    Tooltip,
    Button,
} from "antd";
import {
    MinusCircleOutlined,
    CopyOutlined,
    PlusCircleOutlined,
    BulbOutlined,
    CheckOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { GREY } from "../../constants";
import { debounce } from "lodash";
import { getEmailAssineeList } from "../../Utilities/getAssineeList";
import { useResize } from "../../Utilities/useResize";
import { useStickyState } from "../../Utilities/useStickyState";
import { closableMessage } from "../../Utilities/closableMessage";
import { compareDates } from "../../Utilities/compareDates";
import CopyButton from "../../Utilities/CopyButton";
import UpdateEmailOrderButton from "./UpdateEmailOrderButton";
const { TextArea } = Input;

const EmailDetails = ({ location }) => {
    const { emailId } = useParams();
    const [form] = Form.useForm();
    const [email, setEmail] = useState(null);
    const history = useHistory()
    const [customer, setCustomer] = useState({});
    const [shipping, setShipping] = useState({});
    const [products, setProducts] = useState([]);
    const [showComments, setShowComments] = useStickyState(
        true,
        "showComments"
    );
    const [addingItemIndex, setAddingItemIndex] = useState(null);
    const [productSearchValue, setProductSearchValue] = useState("");
    const [productOptions, setProductOptions] = useState([]);
    const [sortedProductOptions, setSortedProductOptions] = useState([]);
    const [quantityError, setQuantityError] = useState({});
    const [orderNumber, setOrderNumber] = useState("");
    const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false);
    const [deleteConfirmLoading, setDeleteConfirmLoading] = useState(false);
    const [backorders, setBackorders] = useState([]);
    const [allCustomers, setAllCustomers] = useState([]);
    const [minimizeEmailView] = useStickyState(false, "minimizeEmailView");
    const [activeTab, setActiveTab] = useStickyState(
        "customer",
        "emailDetailsActiveTab"
    );
    const [bulkCopiedIds, setBulkCopiedIds] = useStickyState(
        [],
        "bulkCopiedIds"
    );
    const [categories, setCategories] = useState([]);
    const [memoText, setMemoText] = useState("");
    const [users, setUsers] = useState([]);
    const [assigneeList, setAssigneeList] = useState([]);
    const panelRef = useRef(null);
    const quantityInputRef = useRef([]);

    const { initResize, cursor } = useResize(panelRef, {
        axis: "horizontal",
        step: 5,
        minWidth: 400,
    });


    const { data: customerData } = useQuery(GET_CUSTOMERS, {
        onCompleted: (data) => {
            setAllCustomers(data.customers.edges);
        },
    });

    useQuery(GET_USER_ONLINE, {
        onCompleted: (data) => {
            setUsers(data.users?.edges.map(({ node }) => {
                return {
                    ...node,
                    value: node.id,
                    label: node.username,
                    username: node.username,
                    id: node.id
                };
            }));
        },
        fetchPolicy: 'network-only'
    });

    const { data: categoryData, loading: categoryLoading } = useQuery(GET_CATEGORIES);

    const [updateEmailOrder] = useMutation(UPDATE_EMAIL_ORDER, {
        onCompleted: () => {
            refetch();
        },
        onError: (error) => {
            closableMessage("updateEmailOrderError", "error", error.message, 0);
        },
    });

    const [getProducts, { loading: loadingProducts }] = useLazyQuery(
        GET_PRODUCTS,
        {
            onCompleted: (data) => {
                setProductOptions(
                    data.products.edges.map((product) => ({
                        value: product.node.id,
                        label: `${product.node.code} - ${product.node.name}`,
                        code: product.node.code,
                        name: product.node.name,
                    }))
                );
            },
        }
    );

    useEffect(() => {
        // reorder the products to go from exact match to partial match
        let exactMatches = [];
        let partialMatches = [];
        let partialMatchesNoDashes = [];
        productOptions.forEach((product) => {
            const code = product.code.toLowerCase();
            const name = product.name.toLowerCase();
            const value = productSearchValue.toLowerCase();
            if (code === value || name === value) {
                exactMatches.push(product);
            } else if (code.includes(value) || name.includes(value)) {
                partialMatches.push(product);
            } else if (
                code.replaceAll("-", "").includes(value.replaceAll("-", ""))
            ) {
                partialMatchesNoDashes.push(product);
            }
        });
        const sortAlpha = (arr) =>
            arr.sort((a, b) => {
                const aCode = a.code.toLowerCase();
                const bCode = b.code.toLowerCase();
                return aCode < bCode ? -1 : aCode > bCode ? 1 : 0;
            });
        exactMatches = sortAlpha(exactMatches);
        partialMatches = sortAlpha(partialMatches);
        partialMatchesNoDashes = sortAlpha(partialMatchesNoDashes);

        const reorderedProducts = [
            ...exactMatches,
            ...partialMatches,
            ...partialMatchesNoDashes,
        ];
        setSortedProductOptions(reorderedProducts);
    }, [productOptions, productSearchValue]);

    const [getCustomer] = useLazyQuery(GET_CUSTOMER, {
        onCompleted: (data) => {
            setCustomer(data.customer);
            if (data.customer.shippings.length === 1) {
                setShipping(data.customer.shippings[0]);
            }
            updateEmailOrder({
                variables: {
                    input: {
                        id: emailId,
                        customerId: parseInt(data.customer.id),
                        shippingId: null,
                    },
                },
            });
        },
    });

    const [getEmailOrdersProducts, { loading: loadingEmailOrdersProducts }] =
        useLazyQuery(GET_EMAIL_ORDERS_PRODUCTS, {
            fetchPolicy: "no-cache",
            onCompleted: (data) => {
                parseProducts(data.emailOrder);
            },
        });

    const [updateEmailOrderMemo] = useMutation(UPDATE_EMAIL_ORDER, {
        onError: (error) => {
            error.graphQLErrors.forEach((e) => {
                closableMessage("updateMemoError", "error", e?.message, 0);
            });
        },
        onCompleted: () => {
            closableMessage(
                "updateMemoSuccess",
                "success",
                "メモを保存しました。",
                3
            );
        },
    });

    const [updateShipping] = useMutation(UPDATE_SHIPPING, {
        onError: (error) => console.log(error),
    });

    const [removeProductFromEmailOrder] = useMutation(
        REMOVE_PRODUCT_FROM_EMAIL_ORDER,
        {
            onError: (error) => console.log(error),
        }
    );

    const removeProduct = (variableObj) => {
        removeProductFromEmailOrder(variableObj);
    };

    const [updateOneEmailOrdersProduct] = useMutation(
        UPDATE_ONE_EMAIL_ORDERS_PRODUCT,
        {
            onCompleted: () => {
                getEmailOrdersProducts({
                    variables: { emailOrderId: parseInt(emailId) },
                });
            },
        }
    );

    const [updateMultipleEmailOrdersProducts] = useMutation(
        UPDATE_MULTIPLE_EMAIL_ORDERS_PRODUCTS,
        {
            onError: (error) => console.log(error),
        }
    );

    const [addProductToEmailOrder] = useMutation(ADD_PRODUCT_TO_EMAIL_ORDER, {
        onError: (error) => console.log(error),
        onCompleted: () => {
            getEmailOrdersProducts({
                variables: { emailOrderId: parseInt(emailId) },
            });
            message.success("商品を追加しました。");
        },
    });

    const [deleteEmailOrders] = useMutation(DESTROY_EMAIL_ORDERS, {
        onCompleted: () => {
            setDeleteConfirmLoading(false);
            closableMessage('deleteEmailOrderSuccess', 'success', 'メールを削除しました。')
            history.push('/emails');
        },
        onError: () => {
            closableMessage('deleteEmailOrderError', 'error', 'メールの削除に失敗しました。');
        }
    });

    const parseProducts = (emailOrder) => {
        const newProducts = emailOrder.products
            .map((product) => {
                const op = emailOrder.emailOrdersProducts?.find(
                    (emailOrdersProduct) =>
                        emailOrdersProduct.productId === product.id
                );
                return {
                    ...product,
                    displayOrder: op?.displayOrder,
                    emailOrdersProductId: op?.id,
                    quantity: op?.quantity,
                    quantityConfidence: op?.quantityConfidence,
                    confidence: op?.confidence,
                    isSelected: op?.isSelected,
                };
            })
            .sort((a, b) => {
                if (!a.displayOrder) {
                    return 1;
                } else if (!b.displayOrder) {
                    return -1;
                }
                return a.displayOrder - b.displayOrder;
            });
        setProducts((products) => [...newProducts]);
    };

    const { data, refetch, loading, error } = useQuery(GET_EMAIL, {
        variables: { emailOrderId: parseInt(emailId) },
        fetchPolicy: "network-only",
        onError: (error) => {
            console.error("GraphQL error:", error);
        },
    });

    const customerList = useMemo(() => {
        const candidateIds = data?.emailOrder?.emailOrderCustomerCandidates?.map(({customer}) => customer.id) || [];
        const filteredCustomers = customerData?.customers.edges.filter(
            ({ node }) => !candidateIds.includes(node.id)
        );
        return filteredCustomers
    }, [customerData, data])

    useEffect(() => {
        if (data?.emailOrder?.shipping && customer?.shippings) {
            setShipping(
                customer.shippings.find(
                    (shipping) => shipping.id === data.emailOrder.shipping.id
                ) || {}
            );
        }
    }, [data, customer]);

    useEffect(() => {
        if (!loading && !error && data.emailOrder.products) {
            parseProducts(data.emailOrder);
        }
    }, [data, loading, error]);

    useEffect(() => {
        if (data && data.emailOrder) {
            setEmail(data.emailOrder);
        }
    }, [data, form]);

    useEffect(() => {
        if (!loading && !error) {
            if (data.emailOrder.customer) {
                setCustomer(data.emailOrder.customer);
                if (data.emailOrder.customer.shippings.length === 1) {
                    setShipping(data.emailOrder.customer.shippings[0]);
                }
            } else if (data.emailOrder.orderType?.customers.length === 1) {
                setCustomer(data.emailOrder.orderType.customers[0]);
            }
            setOrderNumber(data.emailOrder.orderNumber);
            const memoContent = data.emailOrder.memo;
            setMemoText(memoContent ? memoContent : "");
            setBackorders(data.emailOrder.emailBackorders);
        }
    }, [data, loading, error]);

    useEffect(() => {
        if (categoryData?.categories) {
            setCategories(categoryData.categories.edges.sort((a, b) => a.node.sortOrder - b.node.sortOrder)
                .map(({ node }, index) => {
                    return {
                        value: node.id,
                        label: node.displayName,
                        id: node.id
                    };
            }));
        }
    }, [categoryData, categoryLoading]);
    const { data: assigneeData } = useQuery(GET_EMAIL_ASSIGNEE, {
        fetchPolicy: 'no-cache',
        variables: { activeTab: null },
    });

    useEffect(() => {
        if (users && assigneeData) {
            setAssigneeList(getEmailAssineeList(users, assigneeData))
        }
    }, [users, assigneeData])

    const handleMemoTextChange = (e) => {
        const { value } = e.target;
        setMemoText(value);
    };

    const handleOrderNumberChange = (e) => {
        const { value } = e.target;
        setOrderNumber(value);
    };


    const saveOrderNumber = () => {
        updateEmailOrder({ variables: { input: { id: emailId, orderNumber: orderNumber || null } } })
    };

    const saveMemo = () => {
        updateEmailOrderMemo({
            variables: { input: { id: emailId, memo: memoText || null } },
        });
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        let items = Array.from(products);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        items = items.map((item, index) => ({
            ...item,
            display_order: index + 1,
        }));
        setProducts(items);
        updateMultipleEmailOrdersProducts({
            variables: {
                input: { emailOrderId: parseInt(emailId), products: items },
            },
        });
    };

    const [createEmailBackorder] = useMutation(CREATE_EMAIL_BACKORDER, {
        onCompleted: (data) => {
            setBackorders([...backorders, data.createEmailBackorder.emailBackorder]);
        },
        onError: (error) => {
            console.log(error.message)
        }
    });

    const [removeEmailBackorder] = useMutation(DELETE_EMAIL_BACKORDER, {
        onCompleted: () => {
            closableMessage('deleteEmailBackorderSuccess', 'success', '発注番号を削除しました')
        },
        onError: (error) => {
            console.log(error.message)
        }
    });

    const handleDeleteEmailOrder = () => {
        setDeleteConfirmLoading(true);
        deleteEmailOrders({ variables: { input: { ids: [emailId] } } });
    }

    const addEmailBackorder = (number) => {
        form.setFieldsValue({ backorder: '' });
        createEmailBackorder({
            variables: {
                input: {
                    emailOrderId: emailId,
                    number: number
                }
            }
        });
    }

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            if (value.match(/[ァ-ン]/)) {
                message.error("全角カタカナは入力できません。");
                return;
            }
            setProductOptions([]);
            setProductSearchValue(value);
            getProducts({ variables: { search: value } });
        };

        return debounce(loadOptions, 800);
    }, [getProducts]);

    const [productRecommendations, setProductRecommendations] = useState([]);

    const { loading: recsLoading } = useQuery(GET_PRODUCT_RECOMMENDATIONS, {
        variables: { customerId: parseInt(customer?.id) },
        onCompleted: (data) => {
            const mapped = [...data.productRecommendations]
                .sort((a, b) => {
                    if (a.product.code < b.product.code) {
                        return -1;
                    } else if (a.product.code > b.product.code) {
                        return 1;
                    } else {
                        return 0;
                    }
                })
                .map((product) => {
                    return {
                        ...product.product,
                        key: product.id,
                        timestamp: product.createdAt,
                        confidence: product.confidence,
                    };
                });
            setProductRecommendations(mapped);
        },
    });

    const recommendation_columns = [
        {
            title: <Typography.Text strong>品番</Typography.Text>,
            dataIndex: "code",
        },
        {
            title: <Typography.Text strong>商品名</Typography.Text>,
            dataIndex: "name",
            ellipsis: true,
        },
        {
            title: <Typography.Text strong>信頼度</Typography.Text>,
            dataIndex: "confidence",
            render: (text) => parseFloat(text).toFixed(3),
        },
        {
            render: (record) => <CopyButton text={record.code} />,
            width: 50,
        },
    ];
    if (loading) return <Spin />;
    if (error)
        return <Typography.Text>Error loading email details</Typography.Text>;

    const copyAllProducts = () => {
        // copy all products to clipboard, 1 per line
        const productsString = products
            .map((product) => `${product.code}\n${product.quantity || 0}`)
            .join("\n");
        navigator.clipboard.writeText(productsString);
        setBulkCopiedIds((prev) => {
            const newIds = [...prev];
            newIds.unshift(emailId);
            return newIds.slice(0, 50); // only keep last 50
        });
        closableMessage("success", "success", "すべての商品をコピーしました。");
    };

    const copySelectedProducts = () => {
        // copy selected products to clipboard, 1 per line
        const productsString = products
            .filter((product) => product.isSelected)
            .map((product) => `${product.code}\n${product.quantity || 0}`)
            .join("\n");
        navigator.clipboard.writeText(productsString);
        setBulkCopiedIds((prev) => {
            const newIds = [...prev];
            newIds.unshift(emailId);
            return newIds.slice(0, 50); // only keep last 50
        });
        closableMessage("success", "success", "選択した商品をコピーしました。");
    };

    return (
        <>
            <Row justify="space-between">
                <Col>
                    <Typography.Title
                        level={5}
                        style={{ alignSelf: "center", marginBottom: 12 }}
                    >
                        データ名: {email?.subject}
                    </Typography.Title>
                </Col>
            </Row>
        <Row justify='space-between'>
            <Col>
                <Space>
                    カテゴリー:
                    <Select
                        placeholder='選択してください'
                        style={{ width: 130 }}
                        allowClear
                        showSearch
                        value={email?.category?.id}
                        onChange={e => {
                            updateEmailOrder({ variables: { input: { id: emailId, categoryId: e || null } } })
                        }}
                    >
                        { categories?.length > 0 && categories.map((category, idx) =>
                                <Select.Option
                                    key={idx}
                                    value={category.id}
                                >
                                    { category.label }
                                </Select.Option>
                            )
                        }
                    </Select>
                    担当者:
                    <Select
                        placeholder='選択してください'
                        style={{ width: 165 }}
                        allowClear
                        showSearch
                        value={email?.assignee?.id}
                        onChange={e => {
                            updateEmailOrder({ variables: { input: { id: emailId, assigneeId: e || null } } })
                        }}
                    >
                        { assigneeList?.length > 0 && assigneeList.map((assignee, idx) =>
                                <Select.Option
                                    key={idx}
                                    value={assignee.id}
                                    style={{ backgroundColor:
                                                assignee.online
                                                ? "#FEFEB6"
                                                : assignee.employmentType === 'hold'
                                                ? "lightgray"
                                                :  "" }}
                                >
                                    <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <Typography.Text strong>
                                            {assignee.username}
                                        </Typography.Text>
                                        <Typography.Text strong>
                                            {assignee.count || '0'}
                                        </Typography.Text>
                                    </Space>
                                </Select.Option>
                            )
                        }
                    </Select>
                    伝票番号:
                    <Input
                        data-testid='order-number-input'
                        placeholder='伝票番号'
                        value={orderNumber}
                        onChange={handleOrderNumberChange}
                        style={{ width: 100 }}
                    />
                    <Button data-testid='order-number-save-button' onClick={saveOrderNumber}>保存</Button>
                    <Divider type='vertical' />
                    <UpdateEmailOrderButton
                        id={parseInt(emailId)}
                        text={'受信箱'}
                        attribute={'activeTab'}
                        value={'inbox'}
                        handleCompleted={ () => {
                            closableMessage('success', 'success', 'アップロード済みタブに保存しました');
                        } }
                    />
                    <UpdateEmailOrderButton
                        id={parseInt(emailId)}
                        text={'未完了'}
                        attribute={'activeTab'}
                        value={'incomplete'}
                        handleCompleted={ () => {
                            closableMessage('success', 'success', '未完了タブに保存しました');
                        } }
                    />
                    <UpdateEmailOrderButton
                        id={parseInt(emailId)}
                        text={'不足・取寄'}
                        attribute={'activeTab'}
                        value={'backordered'}
                        handleCompleted={ () => {
                            closableMessage('success', 'success', '不足・取寄タブに保存しました');
                        } }
                    />
                </Space>
                <Space style={{marginLeft: '.5rem'}}>
                    <Divider type='vertical' />
                    <Popconfirm
                        title='削除してよろしいですか。'
                        cancelText='キャンセル'
                        okText='削除する'
                        visible={deletePopConfirmVisible}
                        onConfirm={() => handleDeleteEmailOrder()}
                        okButtonProps={{ loading: deleteConfirmLoading, danger: true }}
                        onCancel={() => setDeletePopConfirmVisible(false)}
                        placement='topRight'
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                        <Button
                            onClick={() => setDeletePopConfirmVisible(true)}
                            icon={<DeleteOutlined/>}>
                            削除
                        </Button>
                    </Popconfirm>
                </Space>
            </Col>
        </Row>
            <Divider />
            <div style={{ display: "flex" }}>
                <div
                    ref={panelRef}
                    style={{
                        display: "flex",
                        width: minimizeEmailView ? "90%" : "33%",
                    }}
                >
                    <Form
                        form={form}
                        labelCol={{ span: 22 }}
                        wrapperCol={{ span: 22 }}
                        labelAlign="left"
                        layout="vertical"
                        colon={false}
                        name="saveEmail"
                        style={{ width: "100%" }}
                    >
                        <Tabs activeKey={activeTab} onChange={setActiveTab}>
                            <Tabs.TabPane tab="得意先" key="customer">
                                <>
                                    <Form.Item name="customer" label="得意先">
                                        <Select
                                            data-testid="customer-select"
                                            showSearch
                                            allowClear
                                            placeholder={
                                                "得意先名を入力して選択してください。"
                                            }
                                            onSelect={(customerId) => {
                                                const c = allCustomers.find(
                                                    (customer) =>
                                                        customer.node.id ===
                                                        customerId
                                                ).node;
                                                getCustomer({
                                                    variables: {
                                                        id: parseInt(c.id),
                                                    },
                                                });
                                                updateEmailOrder({
                                                    variables: {
                                                        input: {
                                                            id: emailId,
                                                            customerId:
                                                                parseInt(
                                                                    customerId
                                                                ),
                                                            shippingId: null,
                                                        },
                                                    },
                                                });
                                                setShipping({});
                                            }}
                                            onClear={() => {
                                                setCustomer({});
                                                updateEmailOrder({
                                                    variables: {
                                                        input: {
                                                            id: emailId,
                                                            customerId: null,
                                                            shippingId: null,
                                                        },
                                                    },
                                                });
                                                setShipping(undefined);
                                            }}
                                            filterOption={(input, option) => {
                                                if (option.children) {
                                                    return option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                        ? true
                                                        : false;
                                                } else if (option.label) {
                                                    return option.label
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                        ? true
                                                        : false;
                                                }
                                            }}
                                        >
                                            <Select.OptGroup label="AI候補">
                                                {data?.emailOrder?.emailOrderCustomerCandidates?.length > 0 &&
                                                    data?.emailOrder?.emailOrderCustomerCandidates?.sort(((a, b) => a.rank - b.rank)).map(
                                                        ({customer}, idx) => (
                                                            <Select.Option
                                                                key={idx}
                                                                value={
                                                                    customer.id
                                                                }
                                                            >
                                                                {`${
                                                                    customer.nameOne
                                                                } ${
                                                                    customer.nameTwo ||
                                                                    ""
                                                                }`}
                                                            </Select.Option>
                                                        )
                                                    )}
                                            </Select.OptGroup>
                                            <Select.OptGroup label="マスターデータ">
                                                {customerList &&
                                                    customerList.map(
                                                        ({
                                                            node: customer,
                                                        }) => {
                                                            return (
                                                                <Select.Option
                                                                    key={
                                                                        customer.code
                                                                    }
                                                                    value={
                                                                        customer.id
                                                                    }
                                                                >
                                                                    {`${
                                                                        customer.nameOne
                                                                    } ${
                                                                        customer.nameTwo ||
                                                                        ""
                                                                    }`}
                                                                </Select.Option>
                                                            );
                                                        }
                                                    )}
                                            </Select.OptGroup>
                                        </Select>
                                    </Form.Item>
                                    {customer.code && (
                                        <>
                                            <Form.Item
                                                name="customer"
                                                label="得意先名"
                                                initialValue={`${
                                                    customer.nameOne
                                                } ${customer.nameTwo || ""}`}
                                            >
                                                <Input
                                                    suffix={
                                                        <CopyButton
                                                            text={`${
                                                                customer.nameOne
                                                            } ${
                                                                customer.nameTwo ||
                                                                ""
                                                            }`}
                                                        />
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="customerCode"
                                                label="得意先コード①"
                                                initialValue={customer.code.slice(
                                                    0,
                                                    -2
                                                )}
                                            >
                                                <Input
                                                    suffix={
                                                        <CopyButton
                                                            text={customer.code.slice(
                                                                0,
                                                                -2
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="officeCode"
                                                label="得意先コード②"
                                                initialValue={customer.code.slice(
                                                    -2
                                                )}
                                            >
                                                <Input
                                                    suffix={
                                                        <CopyButton
                                                            text={customer.code.slice(
                                                                -2
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="kananame"
                                                label="カナ名"
                                                initialValue={customer.kanaName}
                                            >
                                                <Input
                                                    suffix={
                                                        <CopyButton
                                                            text={
                                                                customer.kanaName
                                                            }
                                                        />
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="zipcode"
                                                label="郵便番号"
                                                initialValue={customer.zipcode}
                                            >
                                                <Input
                                                    suffix={
                                                        <CopyButton
                                                            text={
                                                                customer.zipcode
                                                            }
                                                        />
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="address"
                                                label="住所"
                                                initialValue={customer.address}
                                            >
                                                <Input
                                                    suffix={
                                                        <CopyButton
                                                            text={
                                                                customer.address
                                                            }
                                                        />
                                                    }
                                                />
                                            </Form.Item>
                                            <Row>
                                                <Col span="12">
                                                    <Form.Item
                                                        name="phone"
                                                        label="TEL"
                                                        initialValue={
                                                            customer.phone
                                                        }
                                                    >
                                                        <Input
                                                            suffix={
                                                                <CopyButton
                                                                    text={
                                                                        customer.phone
                                                                    }
                                                                />
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span="11">
                                                    <Form.Item
                                                        name="fax"
                                                        label="FAX"
                                                        initialValue={
                                                            customer.fax
                                                        }
                                                    >
                                                        <Input
                                                            suffix={
                                                                <CopyButton
                                                                    text={
                                                                        customer.fax
                                                                    }
                                                                />
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Form.Item
                                                name="memo"
                                                label="得意先備考"
                                                initialValue={customer.memo}
                                            >
                                                <TextArea
                                                    suffix={
                                                        <CopyButton
                                                            text={customer.memo}
                                                        />
                                                    }
                                                />
                                            </Form.Item>
                                        </>
                                    )}
                                </>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="納品先" key="shipping">
                                <Form.Item
                                    name="shipping"
                                    label="納品先"
                                    initialValue={shipping?.id}
                                >
                                    <Select
                                        data-testid="shipping-select"
                                        allowClear
                                        showSearch
                                        placeholder={
                                            "納品先名を入力して選択してください。"
                                        }
                                        onSelect={(shippingId) => {
                                            setShipping(
                                                customer.code &&
                                                    customer.shippings.find(
                                                        (shipping) =>
                                                            shipping?.id ===
                                                            shippingId
                                                    )
                                            );
                                            const currentDateTime = new Date();
                                            updateEmailOrder({
                                                variables: {
                                                    input: {
                                                        id: emailId,
                                                        shippingId,
                                                    },
                                                },
                                            });
                                            updateShipping({
                                                variables: {
                                                    input: {
                                                        id: shippingId,
                                                        lastSelectedAt:
                                                            currentDateTime,
                                                    },
                                                },
                                            });
                                        }}
                                        onClear={() => {
                                            setShipping({});
                                            updateEmailOrder({
                                                variables: {
                                                    input: {
                                                        id: emailId,
                                                        shippingId: null,
                                                    },
                                                },
                                            });
                                        }}
                                        filterOption={(input, option) => {
                                            const chunks = input
                                                .split(" ")
                                                .map((e) => e.toLowerCase());
                                            const name =
                                                option.props.children.props.children.toLowerCase(); // thank you antd
                                            const title =
                                                option.props.children.props.title.toLowerCase();
                                            return chunks.every(
                                                (chunk) =>
                                                    name.includes(chunk) ||
                                                    title.includes(chunk)
                                            );
                                        }}
                                    >
                                        {customer.code &&
                                            customer.shippings
                                                ?.sort((a, b) => {
                                                    return (
                                                        a.kanaName.localeCompare(
                                                            b.kanaName,
                                                            "ja"
                                                        ) ||
                                                        compareDates(
                                                            a.lastSelectedAt,
                                                            b.lastSelectedAt
                                                        )
                                                    );
                                                })
                                                .map((shipping) => (
                                                    <Select.Option
                                                        key={shipping?.id}
                                                        value={shipping?.id}
                                                    >
                                                        <Tooltip
                                                            title={
                                                                shipping?.address +
                                                                " - " +
                                                                shipping?.zipcode +
                                                                " - " +
                                                                shipping?.phone +
                                                                " - " +
                                                                shipping?.kanaName
                                                            }
                                                            placement="right"
                                                        >
                                                            {`${
                                                                shipping?.nameOne
                                                            } ${
                                                                shipping?.nameTwo ||
                                                                ""
                                                            }`}
                                                        </Tooltip>
                                                    </Select.Option>
                                                ))}
                                    </Select>
                                </Form.Item>
                                {shipping?.id && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Typography.Text>
                                            納品先名
                                        </Typography.Text>
                                        <Input
                                            value={`${shipping?.nameOne} ${
                                                shipping?.nameTwo || ""
                                            }`}
                                            className="shipping-input"
                                            suffix={
                                                <CopyButton
                                                    text={`${
                                                        shipping?.nameOne
                                                    } ${
                                                        shipping?.nameTwo || ""
                                                    }`}
                                                />
                                            }
                                        />
                                        <Typography.Text>
                                            カナ名
                                        </Typography.Text>
                                        <Input
                                            value={shipping?.kanaName}
                                            className="shipping-input"
                                            suffix={
                                                <CopyButton
                                                    text={shipping?.kanaName}
                                                />
                                            }
                                        />
                                        <Typography.Text>
                                            郵便番号
                                        </Typography.Text>
                                        <Input
                                            value={shipping?.zipcode}
                                            className="shipping-input"
                                            suffix={
                                                <CopyButton
                                                    text={shipping?.zipcode}
                                                />
                                            }
                                        />
                                        <Typography.Text>住所</Typography.Text>
                                        <Input
                                            value={shipping?.address}
                                            className="shipping-input"
                                            suffix={
                                                <CopyButton
                                                    text={shipping?.address}
                                                />
                                            }
                                        />
                                        <Row>
                                            <Col
                                                span="12"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Typography.Text>
                                                    TEL
                                                </Typography.Text>
                                                <Input
                                                    value={shipping?.phone}
                                                    className="shipping-input"
                                                    suffix={
                                                        <CopyButton
                                                            text={
                                                                shipping?.phone
                                                            }
                                                        />
                                                    }
                                                />
                                            </Col>
                                            <Col
                                                span="11"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Typography.Text>
                                                    FAX
                                                </Typography.Text>
                                                <Input
                                                    value={shipping?.fax}
                                                    className="shipping-input"
                                                    suffix={
                                                        <CopyButton
                                                            text={shipping?.fax}
                                                        />
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="商品内容" key="product">
                                <Button
                                    onClick={() =>
                                        setShowComments((prev) => !prev)
                                    }
                                >
                                    {showComments ? "備考非表示" : "備考表示"}
                                </Button>
                                <DragDropContext
                                    key="current-page-items"
                                    onDragEnd={(res) => handleDragEnd(res)}
                                >
                                    <Droppable droppableId="products">
                                        {(provided) => (
                                            <div
                                                className="products"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {addingItemIndex !== -1 ? (
                                                    <Divider
                                                        className="add-product-divider"
                                                        data-testid="add-product-button"
                                                        dashed
                                                        style={{
                                                            margin: ".25rem 0",
                                                            paddingRight:
                                                                "3.5rem",
                                                        }}
                                                    >
                                                        <PlusCircleOutlined
                                                            className="copy-icon"
                                                            onClick={() => {
                                                                setAddingItemIndex(
                                                                    -1
                                                                );
                                                            }}
                                                        />
                                                    </Divider>
                                                ) : (
                                                    <Form.Item label="商品">
                                                        <Select
                                                            showSearch
                                                            filterOption={false}
                                                            key={
                                                                "selectProduct"
                                                            }
                                                            placeholder={
                                                                "商品コードで検索"
                                                            }
                                                            onSearch={
                                                                debounceFetcher
                                                            }
                                                            notFoundContent={
                                                                loadingProducts ? (
                                                                    <Spin size="small" />
                                                                ) : null
                                                            }
                                                            options={
                                                                sortedProductOptions
                                                            }
                                                            onSelect={(
                                                                productId
                                                            ) => {
                                                                addProductToEmailOrder(
                                                                    {
                                                                        variables:
                                                                            {
                                                                                input: {
                                                                                    emailOrderId:
                                                                                        emailId,
                                                                                    productId,
                                                                                    displayOrder: 1,
                                                                                },
                                                                            },
                                                                    }
                                                                );
                                                                setAddingItemIndex(
                                                                    false
                                                                );
                                                            }}
                                                            autoFocus={true}
                                                        />
                                                    </Form.Item>
                                                )}
                                                {products &&
                                                    products.map(
                                                        (product, index) => {
                                                            let tooltip = "";
                                                            if (
                                                                product.comments
                                                            ) {
                                                                tooltip =
                                                                    "備考: " +
                                                                    product.comments;
                                                            }
                                                            return (
                                                                <Draggable
                                                                    key={`stockItem${product.id}`}
                                                                    draggableId={
                                                                        product.id
                                                                    }
                                                                    index={
                                                                        index
                                                                    }
                                                                >
                                                                    {(
                                                                        provided
                                                                    ) => (
                                                                        <div
                                                                            ref={
                                                                                provided.innerRef
                                                                            }
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    margin: ".25rem 0",
                                                                                    marginRight:
                                                                                        "3.5rem",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        width: "100%",
                                                                                    }}
                                                                                >
                                                                                    <Checkbox
                                                                                        checked={
                                                                                            product.isSelected
                                                                                        }
                                                                                        className="product-checkbox"
                                                                                        onClick={() => {
                                                                                            updateOneEmailOrdersProduct(
                                                                                                {
                                                                                                    variables:
                                                                                                        {
                                                                                                            input: {
                                                                                                                id: product.emailOrdersProductId,
                                                                                                                isSelected:
                                                                                                                    !product.isSelected,
                                                                                                            },
                                                                                                        },
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    <Tooltip title="取り消し">
                                                                                        <Button
                                                                                            icon={
                                                                                                <MinusCircleOutlined />
                                                                                            }
                                                                                            onClick={() => {
                                                                                                removeProduct(
                                                                                                    {
                                                                                                        variables:
                                                                                                            {
                                                                                                                input: {
                                                                                                                    emailOrderId:
                                                                                                                        emailId,
                                                                                                                    productId:
                                                                                                                        product.id,
                                                                                                                },
                                                                                                            },
                                                                                                    }
                                                                                                );
                                                                                                setProducts(
                                                                                                    products.filter(
                                                                                                        (
                                                                                                            p
                                                                                                        ) => {
                                                                                                            return (
                                                                                                                p.id !==
                                                                                                                product.id
                                                                                                            );
                                                                                                        }
                                                                                                    )
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </Tooltip>
                                                                                    <Select
                                                                                        style={{
                                                                                            color: product.confidence
                                                                                                ? "black"
                                                                                                : "blue",
                                                                                            width: "80%",
                                                                                            height: "2rem",
                                                                                        }}
                                                                                        className={
                                                                                            !product.isSelected
                                                                                                ? "not-selected"
                                                                                                : ""
                                                                                        }
                                                                                        showSearch
                                                                                        defaultValue={`${product.code} - ${product.name}`}
                                                                                        filterOption={
                                                                                            false
                                                                                        }
                                                                                        placeholder={
                                                                                            "商品名またはコードを入力してください。"
                                                                                        }
                                                                                        onSearch={
                                                                                            debounceFetcher
                                                                                        }
                                                                                        notFoundContent={
                                                                                            loadingProducts ? (
                                                                                                <Spin size="small" />
                                                                                            ) : null
                                                                                        }
                                                                                        options={
                                                                                            sortedProductOptions
                                                                                        }
                                                                                        onChange={(
                                                                                            v
                                                                                        ) =>
                                                                                            updateOneEmailOrdersProduct(
                                                                                                {
                                                                                                    variables:
                                                                                                        {
                                                                                                            input: {
                                                                                                                id: product.emailOrdersProductId,
                                                                                                                productId:
                                                                                                                    v,
                                                                                                                confidence:
                                                                                                                    null,
                                                                                                            },
                                                                                                        },
                                                                                                }
                                                                                            ).then(
                                                                                                () =>
                                                                                                    message.success(
                                                                                                        "商品を追加しました。"
                                                                                                    )
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <Space
                                                                                        direction="vertical"
                                                                                        size={
                                                                                            0
                                                                                        }
                                                                                        style={{
                                                                                            width: "20%",
                                                                                        }}
                                                                                    >
                                                                                        <Input
                                                                                            key={
                                                                                                product.ordersProductId
                                                                                            }
                                                                                            ref={(
                                                                                                ref
                                                                                            ) =>
                                                                                                (quantityInputRef.current[
                                                                                                    index
                                                                                                ] =
                                                                                                    ref)
                                                                                            }
                                                                                            onPressEnter={(
                                                                                                e
                                                                                            ) => {
                                                                                                e.preventDefault();
                                                                                                if (
                                                                                                    quantityInputRef
                                                                                                        .current[
                                                                                                        index +
                                                                                                            1
                                                                                                    ]
                                                                                                ) {
                                                                                                    quantityInputRef.current[
                                                                                                        index +
                                                                                                            1
                                                                                                    ].focus();
                                                                                                    quantityInputRef.current[
                                                                                                        index +
                                                                                                            1
                                                                                                    ].select();
                                                                                                } else {
                                                                                                    quantityInputRef.current[
                                                                                                        index
                                                                                                    ].blur();
                                                                                                }
                                                                                            }}
                                                                                            type={
                                                                                                "number"
                                                                                            }
                                                                                            style={{
                                                                                                borderColor:
                                                                                                    quantityError[
                                                                                                        `${data.emailOrder.pageNum}-${product.id}`
                                                                                                    ] ||
                                                                                                    product.stock <
                                                                                                        product.quantity
                                                                                                        ? "red"
                                                                                                        : "",
                                                                                            }}
                                                                                            defaultValue={
                                                                                                product.quantity
                                                                                            }
                                                                                            suffix={
                                                                                                product
                                                                                                    .productUnit
                                                                                                    ?.name
                                                                                            }
                                                                                            onBlur={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    parseInt(
                                                                                                        e
                                                                                                            .target
                                                                                                            .value
                                                                                                    ) >
                                                                                                    (product.stock ||
                                                                                                        0)
                                                                                                ) {
                                                                                                    setQuantityError(
                                                                                                        {
                                                                                                            ...quantityError,
                                                                                                            [`${data.emailOrder.pageNum}-${product.id}`]: true,
                                                                                                        }
                                                                                                    );
                                                                                                } else {
                                                                                                    setQuantityError(
                                                                                                        {
                                                                                                            ...quantityError,
                                                                                                            [`${data.emailOrder.pageNum}-${product.id}`]: false,
                                                                                                        }
                                                                                                    );
                                                                                                }
                                                                                                updateOneEmailOrdersProduct(
                                                                                                    {
                                                                                                        variables:
                                                                                                            {
                                                                                                                input: {
                                                                                                                    id: product.emailOrdersProductId,
                                                                                                                    quantity:
                                                                                                                        parseInt(
                                                                                                                            e
                                                                                                                                .target
                                                                                                                                .value
                                                                                                                        ),
                                                                                                                    quantityConfidence:
                                                                                                                        null,
                                                                                                                },
                                                                                                            },
                                                                                                    }
                                                                                                ).then(
                                                                                                    () =>
                                                                                                        message.success(
                                                                                                            "数量を更新しました。"
                                                                                                        )
                                                                                                );
                                                                                            }}
                                                                                            min={
                                                                                                0
                                                                                            }
                                                                                            className="hide-number-arrows"
                                                                                        />
                                                                                        <Tag
                                                                                            data-testid="product-option"
                                                                                            color={
                                                                                                product.stock &&
                                                                                                product.stock >
                                                                                                    0
                                                                                                    ? "success"
                                                                                                    : "error"
                                                                                            }
                                                                                            style={{
                                                                                                alignSelf:
                                                                                                    "self-end",
                                                                                            }}
                                                                                        >
                                                                                            在庫{" "}
                                                                                            {product.stock ||
                                                                                                0}
                                                                                            {
                                                                                                product
                                                                                                    .productUnit
                                                                                                    ?.name
                                                                                            }
                                                                                        </Tag>
                                                                                    </Space>
                                                                                    <CopyButton
                                                                                        buttonMode={
                                                                                            true
                                                                                        }
                                                                                        text={
                                                                                            product.code
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {product.comments &&
                                                                                showComments && (
                                                                                    <Input.TextArea
                                                                                        style={{
                                                                                            maxWidth:
                                                                                                "calc(100% - 3.5rem)",
                                                                                        }}
                                                                                        value={
                                                                                            tooltip
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            <div
                                                                                style={{
                                                                                    marginRight:
                                                                                        "3.5rem",
                                                                                }}
                                                                            >
                                                                                {addingItemIndex !==
                                                                                index ? (
                                                                                    <Divider
                                                                                        className="add-product-divider"
                                                                                        data-testid="add-product-button"
                                                                                        dashed
                                                                                        style={{
                                                                                            margin: ".25rem 0",
                                                                                        }}
                                                                                    >
                                                                                        <PlusCircleOutlined
                                                                                            className="copy-icon"
                                                                                            onClick={() => {
                                                                                                setAddingItemIndex(
                                                                                                    index
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </Divider>
                                                                                ) : (
                                                                                    <Form.Item label="商品">
                                                                                        <Select
                                                                                            showSearch
                                                                                            filterOption={
                                                                                                false
                                                                                            }
                                                                                            key={
                                                                                                "selectProduct"
                                                                                            }
                                                                                            placeholder={
                                                                                                "商品コードで検索"
                                                                                            }
                                                                                            onSearch={
                                                                                                debounceFetcher
                                                                                            }
                                                                                            notFoundContent={
                                                                                                loadingProducts ? (
                                                                                                    <Spin size="small" />
                                                                                                ) : null
                                                                                            }
                                                                                            options={
                                                                                                sortedProductOptions
                                                                                            }
                                                                                            onSelect={(
                                                                                                productId
                                                                                            ) => {
                                                                                                addProductToEmailOrder(
                                                                                                    {
                                                                                                        variables:
                                                                                                            {
                                                                                                                input: {
                                                                                                                    emailOrderId:
                                                                                                                        emailId,
                                                                                                                    productId,
                                                                                                                    displayOrder:
                                                                                                                        product.displayOrder +
                                                                                                                        1,
                                                                                                                },
                                                                                                            },
                                                                                                    }
                                                                                                );
                                                                                                setAddingItemIndex(
                                                                                                    false
                                                                                                );
                                                                                            }}
                                                                                            autoFocus={
                                                                                                true
                                                                                            }
                                                                                        />
                                                                                    </Form.Item>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            );
                                                        }
                                                    )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                    {loadingEmailOrdersProducts && (
                                        <Spin
                                            size="small"
                                            style={{
                                                marginLeft: "40%",
                                                marginBottom: "5%",
                                            }}
                                        />
                                    )}
                                </DragDropContext>
                                {products?.length > 0 && (
                                    <Button
                                        style={{
                                            maxWidth: "calc(100% - 3.5rem)",
                                        }}
                                        type="dashed"
                                        onClick={() =>
                                            products.filter((p) => p.isSelected)
                                                .length > 0
                                                ? copySelectedProducts()
                                                : copyAllProducts()
                                        }
                                        block
                                        icon={
                                            bulkCopiedIds.includes(emailId) ? (
                                                <CheckOutlined />
                                            ) : (
                                                <CopyOutlined />
                                            )
                                        }
                                    />
                                )}
                                {customer?.id && (
                                    <>
                                        {!recsLoading ? (
                                            <Table
                                                style={{
                                                    marginTop: "10rem",
                                                    marginRight: "1rem",
                                                }}
                                                title={() => (
                                                    <Typography>
                                                        オススメ商品
                                                    </Typography>
                                                )}
                                                columns={recommendation_columns}
                                                dataSource={
                                                    productRecommendations
                                                }
                                            />
                                        ) : (
                                            <Spin className="spin-center" />
                                        )}
                                    </>
                                )}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="発注番号" key="backorder">
                                <div className="products">
                                    {backorders &&
                                        backorders.map((backorder) => {
                                            return (
                                                <div
                                                    style={{ display: "flex" }}
                                                    key={backorder.id}
                                                >
                                                    <Form.Item
                                                        name={backorder.id}
                                                        initialValue={
                                                            backorder.number
                                                        }
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <Input
                                                            value={
                                                                backorder.number
                                                            }
                                                            suffix={
                                                                <CopyButton
                                                                    text={
                                                                        backorder.number
                                                                    }
                                                                />
                                                            }
                                                            addonAfter={
                                                                <Tooltip title="取り消し">
                                                                    <MinusCircleOutlined
                                                                        onClick={() => {
                                                                            removeEmailBackorder(
                                                                                {
                                                                                    variables:
                                                                                        {
                                                                                            input: {
                                                                                                emailBackorderId:
                                                                                                    backorder.id,
                                                                                            },
                                                                                        },
                                                                                }
                                                                            );
                                                                            setBackorders(
                                                                                backorders.filter(
                                                                                    (
                                                                                        b
                                                                                    ) => {
                                                                                        return (
                                                                                            b.id !==
                                                                                            backorder.id
                                                                                        );
                                                                                    }
                                                                                )
                                                                            );
                                                                        }}
                                                                        className="minus-icon"
                                                                    />
                                                                </Tooltip>
                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>
                                            );
                                        })}
                                    <Form.Item
                                        name="backorder"
                                        style={{ width: "100%" }}
                                    >
                                        <Input
                                            data-testid="backorder-input"
                                            placeholder="発注番号を入力してください。"
                                            onPressEnter={(e) =>
                                                addEmailBackorder(e.target.value)
                                            }
                                            suffix={
                                                <Button
                                                    data-testid="backorder-save-button"
                                                    onClick={(e) =>
                                                        addEmailBackorder(
                                                            form.getFieldValue(
                                                                "backorder"
                                                            )
                                                        )
                                                    }
                                                    type="primary"
                                                >
                                                    OK
                                                </Button>
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={
                                    <span>
                                        {memoText?.length > 0 && (
                                            <BulbOutlined
                                                style={{
                                                    marginRight: ".25rem",
                                                }}
                                            />
                                        )}
                                        メモ
                                    </span>
                                }
                                key="memo"
                            >
                                <TextArea
                                    data-testid="memo-input"
                                    style={{
                                        width: "95%",
                                    }}
                                    rows={6}
                                    value={memoText}
                                    onChange={handleMemoTextChange}
                                    placeholder="ここに入力してください。"
                                    onBlur={saveMemo}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </Form>
                    <div
                        style={{
                            width: "5px",
                            height: "100%",
                            cursor: "ew-resize",
                        }}
                        cursor={cursor}
                        onMouseDown={initResize}
                    />
                </div>
                <div
                    style={{
                        backgroundColor: GREY,
                        marginTop: "-24px",
                        flexGrow: "1",
                        padding: "20px",
                    }}
                >
                    {email && (
                        <div
                            className="email-content"
                            style={{
                                backgroundColor: "white",
                                padding: "20px",
                                borderRadius: "5px",
                            }}
                        >
                            <h2>{email.subject}</h2>
                            <p>
                                <strong>From:</strong> {email.from}
                            </p>
                            <p>
                                <strong>To:</strong> {email.to}
                            </p>
                            <Divider />
                            <pre style={{
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                fontFamily: 'inherit',
                                margin: 0
                            }}>
                                {email.body}
                            </pre>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default withRouter(EmailDetails);
