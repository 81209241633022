import React, { useEffect, useState, useMemo } from 'react';
import propTypes from 'prop-types';
import { PATHS } from '../../constants';
import { Button, Pagination, Spin, Row } from 'antd';
import { CloudDownloadOutlined, EyeInvisibleOutlined, EyeOutlined, RotateLeftOutlined, RotateRightOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { BoundingBox } from '../';
import { UPDATE_ORDER } from '../../mutations';
import { useMutation } from '@apollo/client';
import { Document, Page, pdfjs } from 'react-pdf';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { closableMessage } from '../../Utilities/closableMessage';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const OrderPdf = ({
    pageWidth, file, unmodifiable,
    onChange: handleChange, getAspectRatio, annotationData,
    orderTypeDimensions, setNewOrderTypeWidth, setNewOrderTypeHeight,
    disablePanning, initialPageNum, isOrderBoundingBoxes, scale,
    showHideButton, siblings, componentHeight, showBoundingBoxes,
    rotationButtons, rotation,
    orderId
}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(initialPageNum || 1);
    const [pdfObject, setPdfObject] = useState(null);
    const [pdfContents, setPdfContents] = useState(null);
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const [hideBoxes, setHideBoxes] = useState(!showBoundingBoxes || false)
    const [pdfRotation, setPdfRotation] = useState(0);
    const [rotationState, setRotationState] = useState(rotation || 0)
    const aspectRatio = width / height;
    getAspectRatio(aspectRatio);

    const [updateOrder] = useMutation(UPDATE_ORDER, {
        onError: (error) => {
            closableMessage('updateOrderError', 'error', error.message, 0);
        }
    });

    const history = useHistory()

    const rotation_val = useMemo(() => {
        return (rotationState + pdfRotation) % 360
    }, [rotationState, pdfRotation])

    useEffect(() => {
        if (pdfObject) { 
            pdfObject.getPage(pageNumber).then(page => {
                const viewport = page.getViewport({ scale: scale || 1, rotation: rotation_val });
                const canvas = document.createElement('canvas');
                const canvasContext = canvas.getContext('2d');
                canvas.height = viewport.height || viewport.viewBox[3]; /* viewport.height is NaN */
                canvas.width = viewport.width || viewport.viewBox[2]; /* viewport.width is also NaN */
                setHeight(canvas.height);
                setWidth(canvas.width);
                setNewOrderTypeHeight(canvas.height);
                setNewOrderTypeWidth(canvas.width);
                page.render({ canvasContext, viewport }).promise.then(() => { // this is needed for the bounding box component to work correctly
                    setPdfContents(canvas.toDataURL('image/jpeg'));
                }).catch(err => console.log(err.message));
            });
         }
    }, [pdfObject, pageNumber, rotation_val, hideBoxes, scale, setNewOrderTypeHeight, setNewOrderTypeWidth]);

    useEffect(() => {
        setPageNumber(initialPageNum || 1)
    }, [file, initialPageNum])

    const onDocumentLoadSuccess = pdf => {
        setNumPages(pdf.numPages);
        setPdfObject(pdf);
        setPageNumber(pageNumber);
    };

    const rotate = (direction) => {
        const prev = rotationState;
        const newRotation = (prev + direction) % 360
        setRotationState(newRotation)
        updateOrder({ variables: { input: { id: orderId, rotation: newRotation } } });
    }

    const parseFilenameFromS3PresignedUrl = (url) => {
        const splitUrl = url.split('/')
        const path = splitUrl[splitUrl.length - 1]
        const filename = path.split('?')[0]
        if (filename && filename.split('.')[1] === 'pdf') {
            return filename
        } else {
            return null
        }
    }
    const downloadPdf = async () => {
        await axios({ url: file, method: 'GET', responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                const filename = parseFilenameFromS3PresignedUrl(file);
                link.href = url;
                link.setAttribute('download', filename || `order-${orderId}.pdf`);
                document.body.appendChild(link);
                link.click();
            });
    }

    return <TransformWrapper pan={{ disabled: disablePanning }}>
        {({ zoomIn, zoomOut }) => (
            <React.Fragment>
                    <Row justify='left' style={{ margin: '5px', display: "flex", alignItems: "left" }}>
                        <Pagination
                            style={{ margin: '0'}}
                            onChange={page => {
                                if (siblings?.find(e => e.pageNum === page)) {
                                    history.push(PATHS.orderDetails.replace(':orderId', siblings.find(e => e.pageNum === page).id))
                                }
                                setPageNumber(page)
                            }}
                            defaultPageSize={1}
                            current={pageNumber}
                            total={numPages}
                        />
                    </Row>
                    <Row justify='center' style={{ margin: '5px', display: "flex", alignItems: "center" }}>
                        { rotationButtons && <Button shape='round' icon={<RotateLeftOutlined/>} onClick={e => rotate(-90)}/> }
                        { rotationButtons && <Button shape='round' icon={<RotateRightOutlined/>} onClick={e => rotate(90)}/> }
                        <Button shape='round' icon={<ZoomInOutlined/>} onClick={() => zoomIn()} />
                        <Button shape='round' icon={<ZoomOutOutlined/>} onClick={() => zoomOut()} />
                        { showHideButton && <Button shape='round' icon={hideBoxes ? <EyeOutlined/> : <EyeInvisibleOutlined/>} onClick={() => setHideBoxes(!hideBoxes)}/>}
                        <Button shape='round' icon={<CloudDownloadOutlined/>} onClick={downloadPdf} />
                    </Row>
                <Row justify='center'>
                    <TransformComponent>
                        <Document
                            renderMode={"none"}
                            file={file}
                            rotate={rotation_val}
                            loading={<Spin/>}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={console.error}
                            options={{
                                cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                                cMapPacked: true
                            }}
                            error={"PDFを表示できません"}
                        >
                            <Page scale={scale || 1} height={componentHeight} width={ !componentHeight ? pageWidth : null} onLoadSuccess={e => setPdfRotation(e._pageInfo?.rotate)} renderAnnotationLayer={false} pageNumber={pageNumber} />
                        </Document>
                        { pdfContents &&
                            <BoundingBox
                                onChange={handleChange}
                                annotationData={hideBoxes ? [] : annotationData}
                                unmodifiable={unmodifiable}
                                pdfFile={pdfContents}
                                orderTypeDimensions={orderTypeDimensions || { width, height }}
                                originalPdfWidth={width}
                                originalPdfHeight={height}
                                width={(pageWidth || width)}
                                height={(pageWidth / aspectRatio || height)}
                                isOrderBoundingBoxes={isOrderBoundingBoxes}
                                scale={scale || 1}
                            />
                        }
                    </TransformComponent>
                </Row>
            </React.Fragment>
        )}
    </TransformWrapper>;
};

OrderPdf.propTypes = {
    unmodifiable: propTypes.bool,
    renderMode: propTypes.string,
    file: propTypes.string.isRequired,
    annotationData: propTypes.array,
    pageWidth: propTypes.number,
    boundingBoxEnabled: propTypes.bool,
    onChange: propTypes.func,
    getAspectRatio: propTypes.func,
    orderTypeDimensions: propTypes.object,
    setNewOrderTypeWidth: propTypes.func,
    setNewOrderTypeHeight: propTypes.func,
    disablePanning: propTypes.bool,
    isOrderBoundingBoxes: propTypes.bool
};

OrderPdf.defaultProps = {
    unmodifiable: false,
    renderMode: 'canvas',
    boundingBoxEnabled: false,
    disablePanning: false,
    getAspectRatio: () => {},
    setNewOrderTypeWidth: () => {},
    setNewOrderTypeHeight: () => {}
};

export default OrderPdf;
