import { message } from 'antd';

export const closableMessage = (key, type, messageText, duration=5) => {
    message[type]({
        content: messageText,
        key,
        duration,
        onClick: () => message.destroy(key)
    });
}
