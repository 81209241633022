import React, { useState } from 'react';
import propTypes from 'prop-types';
import {
    Typography,
    Table,
    Row,
    Button,
    message
} from 'antd';
import { CREATE_PRODUCTS, CREATE_CUSTOMERS, CREATE_SHIPPINGS, CREATE_PRODUCT_UNITS } from '../../mutations';
import ImportButton from './ImportButton';
import { closableMessage } from '../../Utilities/closableMessage';

const ImportClients = ({ items, type, reset }) => {
    const [badRecords, setBadRecords] = useState([]);
    let mutationType;

    const columns = [
        {
            title: <Typography.Text strong>{items[0][0]}</Typography.Text>,
            render: rowContent => rowContent[0]
        },
        {
            title: <Typography.Text strong>{items[0][1]}</Typography.Text>,
            render: rowContent => rowContent[1]
        },
        {
            title: <Typography.Text strong>{items[0][2]}</Typography.Text>,
            render: rowContent => rowContent[2]
        },
        {
            title: <Typography.Text strong>{items[0][3]}</Typography.Text>,
            render: rowContent => rowContent[3]
        }
    ];
    if (type === 'products') { 
        mutationType = CREATE_PRODUCTS;
    } 
    else if (type === 'customers') {
        mutationType = CREATE_CUSTOMERS;
    }
    else if (type === 'shippings') {
        mutationType = CREATE_SHIPPINGS;
    } else if (type === 'productUnits') {
        mutationType = CREATE_PRODUCT_UNITS;
    } 

    return <>
        <Typography.Title level={5} strong>インポートするデータ</Typography.Title>
        {items.length > 0 &&
            <React.Fragment>
                <Table style={{ paddingTop: '25px' }} columns={columns} dataSource={items.slice(1)}/>
                <ImportButton
                    items={items}
                    mutationType={mutationType}
                    handleCompleted={() => {message.destroy('error'); closableMessage('success', 'success', 'インポートしました。', 5)}}
                    handleError={() => closableMessage('error', 'error', 'インポートできませんでした。もう一度インポートしてください。', 0)}
                    setBadRecords={setBadRecords}
                />
            </React.Fragment>
        }
        {items.length <= 0 &&
            <>
                <Row style={{ margin: '16px 0px' }}>
                    <Typography.Text>新規データが見つかりませんでした</Typography.Text>
                </Row>
                <Row>
                    <Button
                        type='primary'
                        onClick={reset}
                    >
                        販売管理データインポート
                    </Button>
                </Row>
            </>
        }
        {badRecords.length > 0 &&
            <>
                <Row style={{ margin: '16px 0px' }}>
                    <Typography.Text strong>次のデータはインポートできませんでした</Typography.Text>
                </Row>
                {badRecords.map(e => {
                return <Row key={e}>
                    <Typography.Text>{e.map(br => br + ', ')}</Typography.Text>
                </Row>;
        })}
        </>}
        
    </>;
};

ImportClients.propTypes = {
    items: propTypes.array.isRequired,
    setItems: propTypes.func.isRequired,
    type: propTypes.string.isRequired,
    currentData: propTypes.object.isRequired,
    reset: propTypes.func.isRequired
};

export default ImportClients;
