import React, { useState } from "react"
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT, GET_MONTHLY_SALES_PREDICTIONS } from "../../queries";
import { Card, Spin, } from "antd";
import { Line } from '@ant-design/charts'


const ProductDetails = () => {
    const { productId } = useParams();
    const [monthlySalesPredictions, setMonthlySalesPredictions] = useState([]);

    const { data: productData } = useQuery(GET_PRODUCT, {
        variables: { id: parseInt(productId) }
    });

    const {loading: recsLoading} = useQuery(GET_MONTHLY_SALES_PREDICTIONS, {
        variables: { productId: parseInt(productId) },
        onCompleted: (data) => {
            const d = data.monthlySalesPredictions.map((prediction) => {
                return {
                    ...prediction,
                    数量: prediction.quantity,
                }
            });
            setMonthlySalesPredictions(d);
        }
    });

    if (recsLoading) {
        return <Spin style={{margin: "50%", marginTop: "40%"}}/>
    }

    return <>
     {productData && (
      <Card title="商品概要" style={{ width: 300 }}>
        <p><strong>商品名:</strong> {productData.product.name}</p>
        <p><strong>在庫:</strong> {productData.product.stock}</p>
        <p><strong>備考:</strong> {productData.product.comments}</p>
      </Card>
    )}
    <Line xField="monthStartDt" yField="数量" data={monthlySalesPredictions} />
    </>
};

export default ProductDetails;