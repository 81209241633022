import React, { useState, useEffect } from 'react'
import { CopyOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';
import { closableMessage } from './closableMessage';


const CopyButton = ({ text, delay, icon: Icon = CopyOutlined, buttonMode = false }) => {
    const [copied, setCopied] = useState(false)

    const copy = () => {
        navigator.clipboard.writeText(text)
        closableMessage('copySuccess', 'success', 'コピーしました。')
        setCopied(true)
    }

    useEffect(() => {
        if (!delay) return
        if (copied) {
            const timer = setTimeout(() => {
                setCopied(false)
            }, delay)
            return () => clearTimeout(timer)
        }
    }, [copied, delay])


    return (
        <Tooltip title='コピー'>
            {buttonMode ? 
                <Button onClick={copy} icon={<Icon className='copy-icon' style={{ color: copied && '#1890ff'}} />}/> 
                : <Icon onClick={copy} className='copy-icon' style={{ color: copied && '#1890ff'}} />
            }
        </Tooltip>
    )
}

export default CopyButton