import React, { useState } from 'react';
import { Button, Spin, Space, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import propTypes from 'prop-types';
import { CREATE_BOUNDING_BOX } from '../../mutations';
import notificationMessage from '../notificationMessage/notificationMessage';
import { Redirect } from 'react-router-dom';
import { PATHS } from '../../constants';

const CreateOrderBoundingBoxesButton = ({ boundingBoxes, orderId, orderTypeId, aspectRatio, isOrderTypeBoundingBox }) => {
    const [loading, setLoading] = useState(false);
    const [finishedAllMutations, setFinishedAllMutations] = useState(false);

    const [createBoundingBox] = useMutation(CREATE_BOUNDING_BOX, {
        onCompleted: () => {
            setLoading(false);
        },
        onError: (gqlError) => {
            setLoading(false);
            notificationMessage(gqlError);
        }
    });

    const updateBoundingBoxes = (orderId) => {
        boundingBoxes.map(boundingBox => {
            setLoading(true);
            const x2 = boundingBox.mark.x + boundingBox.mark.width;
            const y2 = boundingBox.mark.y + boundingBox.mark.height;
            const coordinates = `(${x2},${y2})(${boundingBox.mark.x},${boundingBox.mark.y})`;
            return createBoundingBox({ variables: { input: {
                coordinates,
                ...(!isOrderTypeBoundingBox) && {orderId},
                ...(isOrderTypeBoundingBox) && {orderTypeId},
                aspectRatio,
                comment: boundingBox.comment
            } } });
        });

        setFinishedAllMutations(true);
    };

    return <Space>
        <Button
            disabled={boundingBoxes.length === 0 || boundingBoxes.map(bb => bb.comment).includes(undefined) }
            type="primary"
            onClick={() => {
                setLoading(true);
                updateBoundingBoxes(orderId);
            }}
        >
            完了
        </Button>
        { loading && <Spin /> }
        { boundingBoxes.map(bb => bb.comment).includes(undefined) &&
            <Typography.Text type='danger'>タイプを選択してください</Typography.Text> }
        { finishedAllMutations && <Redirect to={`${PATHS.orderDetails.replace(':orderId', orderId)}`} /> }
    </Space>;
};

CreateOrderBoundingBoxesButton.propTypes = {
    boundingBoxes: propTypes.array.isRequired,
    aspectRatio: propTypes.number.isRequired,
    orderId: propTypes.number.isRequired,
    orderTypeId: propTypes.number.isRequired,
    isOrderTypeBoundingBox: propTypes.bool.isRequired
};

export default CreateOrderBoundingBoxesButton;