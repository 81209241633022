import React, { useState, useCallback } from 'react';
import {
    Col,
    Typography,
    Row,
    Button,
    Empty,
    Spin
} from 'antd';
import { CloseCircleTwoTone } from '@ant-design/icons';
import '../UploadArea/uploadarea.less';
import { useDropzone } from 'react-dropzone';
import ImportClients from './ImportClients';
import * as XLSX from 'xlsx';
import { GET_CUSTOMERS, GET_ALL_PRODUCTS } from '../../queries';
import { useLazyQuery } from '@apollo/client';
import iconv from 'iconv-lite'

const Import = () => {
    const [currentStatus, setCurrentStatus] = useState('beforeUpload');
    const [products, setProducts] = useState([]);
    const [clients, setClients] = useState([]);
    const [shippings, setShippings] = useState([])
    const [productUnits, setProductUnits] = useState([]);
    const [loadProducts, { data: productData }] = useLazyQuery(GET_ALL_PRODUCTS, { fetchPolicy: 'no-cache' });
    const [loadCustomers, { data: customerData }] = useLazyQuery(GET_CUSTOMERS, { fetchPolicy: 'no-cache' });
    const { Text } = Typography;
    const description = <React.Fragment>
        インポートしたい得意先マスターデータここにドラッグ＆ドロップ
        <br/>
        または
    </React.Fragment>;
    const instruction = <React.Fragment>
        <Text disabled>
        データをインポートする前に以下のことを確認してください。
            <ul>
                <li>
                商品マスターは品番と商品名が入力されていることを確認してください。
                </li>
                <li>
                得意先マスターは得意先1と得意先2と得意先カナ名が入力されていることを確認してください。
                </li>
            </ul>
        </Text>
    </React.Fragment>;

    const onDrop = useCallback(acceptedFiles => {
        setCurrentStatus('loadingUpload');
        const f = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            let data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            data = data.filter(item => {
                return item.length > 0;
            });
            let shiftjis = false
            data[0].forEach(col => {
                if (col.includes('')) {
                    shiftjis = true 
                }
            })
            /* decodes with iconv and reparses if garbled characters found in first row */
            if (shiftjis) {
                const decoded = iconv.decode(bstr, 'shiftjis')
                const wb = XLSX.read(decoded, { type: 'binary' });
                const ws = wb.Sheets[wb.SheetNames[0]];
                data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                data = data.filter(item => {
                    return item.length > 0;
                });
            }

            /* Update state */
            if (data[0].includes('品番')) {
                loadProducts();
                setProducts(data);
                setCurrentStatus('importProducts');
            } 
            else if (data[0].includes('納品先コード')) {
                setShippings(data)
                setCurrentStatus('importShippings')
            }
            else if (data[0].includes('得意先コード')){
                loadCustomers();
                setClients(data);
                setCurrentStatus('importClients');
            } else if (data[0].includes('汎用コード')) {
                setProductUnits(data)
                setCurrentStatus('importProductUnits')
             } else {
                setCurrentStatus('unrecognized')
            }
        };

        reader.readAsBinaryString(f);
    }, [loadCustomers, loadProducts]);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const resetToBeforeUpload = () => {
        setCurrentStatus('beforeUpload');
        setProducts([]);
        setClients([]);
        setShippings([]);
    };

    return <>
        { currentStatus === 'beforeUpload' &&
            <Col>
                <Row justify='center'>
                    <Empty
                        description={description}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        id='upload-zone'
                        data-testid='upload-zone'
                        {...getRootProps()}
                        >
                        <input {...getInputProps()} />
                        <Row justify='center'>
                            <Button type="primary">ファイルを選択</Button>
                        </Row>
                    </Empty>
                </Row>
                <br/>
                <Row>
                    <Typography.Paragraph style={{margin: "0 auto"}}>
                        {instruction}
                    </Typography.Paragraph>
                </Row>
            </Col>
        }
        { (currentStatus === 'loadingUpload') &&
            <>
                <Row justify='center'>
                    <Spin size='large' />
                </Row>
                <br/>
                <Row justify='center'>
                    読み込み中
                </Row>
            </>
        }
        { currentStatus === 'failedUpload' &&
            <>
                <Row justify='center'>
                    <CloseCircleTwoTone style={{ fontSize: '72px' }} twoToneColor='#F5222D' />
                </Row>
                <br/>
                <Row justify='center'>
                    XLSXを分類することができませんでした。
                </Row>
            </>
        }
        { currentStatus === 'importProducts' &&
            <ImportClients
                items={products}
                setItems={setProducts}
                currentData={productData}
                type='products'
                reset={resetToBeforeUpload}
            />
        }
        { currentStatus === 'importClients' &&
            <ImportClients
                items={clients}
                setItems={setClients}
                currentData={customerData}
                type='customers'
                reset={resetToBeforeUpload}
            />
        }
        { currentStatus === 'importShippings' &&
            <ImportClients
                items={shippings}
                setItems={setShippings}
                currentData={{}}
                type='shippings'
                reset={resetToBeforeUpload}
            />
        }
        { currentStatus === 'importProductUnits' &&
            <ImportClients
                items={productUnits}
                setItems={setProductUnits}
                currentData={{}}
                type='productUnits'
                reset={resetToBeforeUpload}
            />
        }
        { currentStatus === "unrecognized" &&
            <Typography.Text strong >フォーマットを認識できませんでした</Typography.Text>
        }
    </>;
};

export default Import;
