import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Space, Progress } from 'antd';
import propTypes from 'prop-types';

const ImportButton = ({ items, handleError, handleCompleted, mutationType, setBadRecords }) => {
    const [progressPercent, setProgressPercent] = useState(0);
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [importData] = useMutation(mutationType, {
        onCompleted: data => {
            if (data[Object.keys(data)[0]]?.badRecords?.length > 0) {
                setBadRecords(prev => [...prev, ...data[Object.keys(data)[0]].badRecords])
            }
        },
        onError: (e) => {
            console.log(e)
            handleError();
            setError(true);
        }
    });

    const createInBatches = async (items) => {
        const headers = items.shift();
        const batchSize = 1000;
        const batchRatio = 100 / Math.ceil(items.length / batchSize);
        const batches = [];
        for (let i = 0; i < items.length; i += batchSize) {
            batches.push([headers, ...items.slice(i, i + batchSize)]);
        }
        for (let i = 0; i < batches.length; i++) {
            await importData({ variables: { input: { body: JSON.stringify(batches[i]) } } });
            setProgressPercent((i + 1) * batchRatio);
        }
        handleCompleted();
    }

    return <Space>
        <Button
            type="primary"
            onClick={() => {
                createInBatches(items)
                setDisabled(true)
            }}
            style={{ top: '-48px' }}
            disabled={disabled}
        >
            インポートする
        </Button>
        <Progress style={{position: "absolute", width: "50%", left: "25%"}} status={error && 'error'} percent={progressPercent}/>
    </Space>;
};

ImportButton.propTypes = {
    items: propTypes.array,
    handleCompleted: propTypes.func,
    handleError: propTypes.func,
    mutationType: propTypes.object,
    disabled: propTypes.bool
};

export default ImportButton;
