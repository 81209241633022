export const compareDates = (d1, d2) => {
    if (!d1 && !d2) {
        return 0;
    } else if (d1 && d2) {
        return new Date(d2) - new Date(d1);
    } else if (d1) {
        return -1
    } else {
        return 1
    }
};
