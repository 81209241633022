import React from 'react';
import {
    Col,
    Card,
    Spin,
    Statistic,
    Row,
    Typography
} from 'antd';
import LineChart from './LineChart';
import BarChart from './BarChart';
import { useQuery } from '@apollo/client';
import { GET_FAXES, GET_ORDERTYPE_AGGREGATES } from '../../queries';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import { getPastDate } from '../../Utilities/getPastDate';
import moment from 'moment';
import { ACTIVE_TAB_MAPPING } from '../../constants';

const Dashboard = () => {
    const { data: ordersData, loading: ordersLoading } = useQuery(GET_FAXES, {
        fetchPolicy: 'no-cache',
        variables: { activeTab: null},
    });
    const { data: aggData, loading: aggLoading } = useQuery(GET_ORDERTYPE_AGGREGATES);

    if (ordersLoading || aggLoading) {
        return <Spin/>;
    }

    const ordersWithStatus = ordersData?.recentFaxes
        .map(
            order => ({
                timestamp: order.createdAt.split('T')[0],
                status: ACTIVE_TAB_MAPPING[order.activeTab],
                user: order.user && order.user.username
            })
        )
        .filter(order => order.user !== null)
        .filter(order => moment(order.createdAt) >= moment(getPastDate(7)));

    const countsByStatus = ordersWithStatus.reduce(
        (acc, order) => {
            if (acc[order.status]) {
                acc[order.status] += 1;
            } else {
                acc[order.status] = 1;
            }
            return acc;
        }, {}
    )

    const newlyUploadedAgg = []
    const doneAgg = []

    aggData.dashboardCounts.edges.forEach(agg => {
        newlyUploadedAgg.push({ ordersCount: agg.node.newUpload, timestamp: agg.node.date.split('T')[0]})
        doneAgg.push({ ordersCount: agg.node.done, timestamp: agg.node.date.split('T')[0]})
    })

    const groupedOrdersByUser = groupBy(ordersWithStatus, 'user');
    const uniqueOrderStatuses = Array.from(new Set(ordersWithStatus.map(order => order.status)));
    const ordersPerUser = flatten(Object.keys(groupedOrdersByUser).map(user => {
        return uniqueOrderStatuses.map(status => {
                return {
                    username: user,
                    type: status,
                    value: groupedOrdersByUser[user].filter(order => order.status === status).length
                }
            })
    }));

    return <>
        <Row gutter={16}>
            <Typography.Title style={{margin: 16 }} level={4}>過去１ヶ月の実績</Typography.Title>
        </Row>
        <Row gutter={16} >
            {countsByStatus && Object.keys(countsByStatus).map((status, idx) => (
                <Col span={4} key={idx}>
                    <Card>
                        <Statistic
                            title={status}
                            value={countsByStatus[status]}
                        />
                    </Card>
                </Col>
            ))}
        </Row>
        <br/>
        <Row gutter={16}>
            <Col span={24}>
                <Card title="社員別進捗状況">
                    <BarChart data={ordersPerUser} />
                </Card>
            </Col>
        </Row>
        <br/>
        <Row gutter={16}>
            <Typography.Title style={{margin: 16 }} level={4}>累計</Typography.Title>
        </Row>
        <Row gutter={16}>
            <Col span={12}>
                <Card title="アップロードされたPDF総数">
                    <LineChart data={newlyUploadedAgg} />
                </Card>
            </Col>
            <Col span={12}>
                <Card title="受注入力完了PDF総数">
                    <LineChart data={doneAgg} />
                </Card>
            </Col>
        </Row>
    </>;
};

export default Dashboard;
