import gql from 'graphql-tag';

export const CREATE_USER = gql`
mutation createUser($input: createUserInput!) {
    createUser(input: $input) {
        user {
            id
            username
            password
        }
    }
}
`;

export const UPDATE_USER = gql`
mutation updateUser($input: updateUserInput!) {
    updateUser(input: $input) {
       user {
           id
           username
           email
           employmentType
       }
    }
}`;

export const CREATE_CUSTOMERS = gql`
mutation createCustomers($input: createCustomersInput!) {
    createCustomers(input: $input) {
        customers {
            id
            code
            kanaName
            nameOne
            nameTwo
        }
    }
}
`;

export const CREATE_SHIPPINGS = gql`
mutation createShippings($input: createShippingsInput!) {
    createShippings(input: $input) {
        shippings {
            id
        }
        badRecords
    }
}
`;

export const UPDATE_SHIPPING = gql`
mutation updateShipping($input: updateShippingInput!) {
    updateShipping(input: $input) {
        shipping {
            id
        }
    }
}
`;

export const CREATE_PRODUCTS = gql`
mutation createProducts($input: createProductsInput!) {
    createProducts(input: $input) {
        products {
            id
        }
    }
}
`;

export const CREATE_PRODUCT_UNITS = gql`
mutation createProductUnits($input: createProductUnitInput!) {
    createProductUnit(input: $input) {
        productUnit {
            id
            code
            name
        }
    }
}`;

export const DELETE_USERS = gql`
mutation deleteUser($input: deleteUserInput!) {
    deleteUser(input: $input) {
        users {
            id
            username
        }
    }
}
`;

export const PASSWORD_RESET = gql`
mutation passwordReset($input: passwordResetUserInput!) {
    passwordResetUser(input: $input) {
        user {
            id
            username
            password
        }
    }
}
`;

export const CHANGE_PASSWORD = gql`
mutation changePassword($input: changePasswordUserInput!) {
    changePasswordUser(input: $input) {
        user {
            id
            username
            password
        }
    }
}
`;

export const DESTROY_ORDERS = gql`
mutation destroyOrder($input: destroyOrderInput!) {
    destroyOrder(input: $input) {
        orders {
            id
        }
    }
}
`;

export const DESTROY_EMAIL_ORDERS = gql`
mutation destroyEmailOrder($input: destroyEmailOrderInput!) {
    destroyEmailOrder(input: $input) {
        emailOrders {
            id
        }
    }
}
`;

export const UPDATE_ORDER = gql`
mutation updateOrder($input: updateOrderInput!) {
    updateOrder(input: $input) {
        order {
            id
        }
    }
}
`;

export const UPDATE_EMAIL_ORDER = gql`
mutation updateEmailOrder($input: updateEmailOrderInput!) {
    updateEmailOrder(input: $input) {
        emailOrder {
            id
        }
    }
}
`;

export const ADD_PRODUCT_TO_ORDER = gql`
mutation addProductToOrder($input: addProductToOrderInput!) {
    addProductToOrder(input: $input) {
        ordersProduct {
            id
        }
    }
}
`;

export const ADD_PRODUCT_TO_EMAIL_ORDER = gql`
mutation addProductToEmailOrder($input: addProductToEmailOrderInput!) {
    addProductToEmailOrder(input: $input) {
        emailOrdersProduct {
            id
        }
    }
}
`;

export const REMOVE_PRODUCT_FROM_ORDER = gql`
mutation removeProductFromOrder($input: removeProductFromOrderInput!) {
    removeProductFromOrder(input: $input) {
        ordersProducts {
            id
        }
    }
}
`;

export const REMOVE_PRODUCT_FROM_EMAIL_ORDER = gql`
mutation removeProductFromEmailOrder($input: removeProductFromEmailOrderInput!) {
    removeProductFromEmailOrder(input: $input) {
        emailOrdersProducts {
            id
        }
    }
}
`;

export const UPDATE_MULTIPLE_ORDERS_PRODUCTS = gql`
mutation updateMultipleOrdersProducts($input: updateMultipleOrdersProductsInput!) {
    updateMultipleOrdersProducts(input: $input) {
        ordersProducts {
            id
        }
    }
}
`;

export const UPDATE_MULTIPLE_EMAIL_ORDERS_PRODUCTS = gql`
mutation updateMultipleEmailOrdersProducts($input: updateMultipleEmailOrdersProductsInput!) {
    updateMultipleEmailOrdersProducts(input: $input) {
        emailOrdersProducts {
            id
        }
    }
}
`;

export const CREATE_NEW_FORMAT = gql`
mutation createOrderType($input: createOrderTypeInput!) {
    createOrderType(input: $input) {
        orderType {
            id
        }
    }
}
`;

export const UPDATE_EXISTING_FORMAT = gql`
mutation updateOrderType($input: updateOrderTypeInput!) {
    updateOrderType(input: $input) {
        orderType {
            id
        }
    }
}
`;

export const CREATE_BOUNDING_BOX = gql`
mutation createBoundingBox($input: createBoundingBoxInput!) {
    createBoundingBox(input: $input) {
        boundingBox {
            id
        }
    }
}
`;

export const CREATE_ORDER = gql`
mutation createOrderFromFrontend($input: createOrderFromFrontendInput!) {
    createOrderFromFrontend(input: $input) {
        order {
            id
            s3ObjectKey
            progress
            userId
        }
    }
}
`;

export const TRIGGER_INFERENCE = gql`
mutation triggerInference($input: triggerInferenceInput!) {
    triggerInference(input: $input) {
        order {
            id
        }
    }
}
`;

export const CREATE_CATEGORY = gql`
mutation createCategory($input: createCategoryInput!) {
    createCategory(input: $input) {
        category {
            id
            name
            code
            displayName
        }
    }
}
`;

export const UPDATE_CATEGORY = gql`
mutation updateCategory($input: updateCategoryInput!) {
    updateCategory(input: $input) {
        category {
            id
            name
            code
            displayName
            sortOrder
        }
    }
}
`;


export const DELETE_CATEGORY = gql`
mutation deleteCategory($input: deleteCategoryInput!) {
    deleteCategory(input: $input) {
        category {
            id
        }
    }
}
`;

export const CREATE_BACKORDER = gql`
mutation createBackorder($input: createBackorderInput!) {
    createBackorder(input: $input) {
        backorder {
            id
            orderId
            number
        }
    }
}`

export const CREATE_EMAIL_BACKORDER = gql`
mutation createEmailBackorder($input: createEmailBackorderInput!) {
    createEmailBackorder(input: $input) {
        emailBackorder {
            id
            emailOrderId
            number
        }
    }
}`

export const DELETE_BACKORDER = gql`
mutation deleteBackorder($input: deleteBackorderInput!) {
    deleteBackorder(input: $input) {
        backorder {
            id
        }
    }
}`

export const DELETE_EMAIL_BACKORDER = gql`
mutation deleteEmailBackorder($input: deleteEmailBackorderInput!) {
    deleteEmailBackorder(input: $input) {
        emailBackorder {
            id
        }
    }
}
`

export const UPDATE_ONE_ORDERS_PRODUCT = gql`
mutation updateOneOrdersProduct($input: updateOneOrdersProductInput!) {
    updateOneOrdersProduct(input: $input) {
        ordersProduct {
            id
        }
    }
}
`

export const UPDATE_ONE_EMAIL_ORDERS_PRODUCT = gql`
mutation updateOneEmailOrdersProduct($input: updateOneEmailOrdersProductInput!) {
    updateOneEmailOrdersProduct(input: $input) {
        emailOrdersProduct {
            id
        }
    }
}
`

export const CREATE_INFERENCE_MISTAKE = gql`
mutation createInferenceMistake($input: createInferenceMistakeInput!) {
    createInferenceMistake(input: $input) {
        inferenceMistake {
            id
        }
    }
}
`
