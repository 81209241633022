import React, { useState } from "react"
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_ORDERS, GET_CUSTOMER, GET_PRODUCT_RECOMMENDATIONS } from "../../queries";
import { Table, Typography, Spin, Descriptions, Row, Col } from "antd";
import { Line } from '@ant-design/charts'
import { PATHS } from '../../constants';


const CustomerDetails = () => {
    const { customerId } = useParams();
    const [rows, setRows] = useState([]);

    const [productHistory, setProductHistory] = useState([]);
    const [productRecommendations, setProductRecommendations] = useState([]);
    const [graphData, setGraphData] = useState([]);

    const { data: customerData } = useQuery(GET_CUSTOMER, {
        variables: { id: parseInt(customerId) }
    });

    const {loading: recsLoading} = useQuery(GET_PRODUCT_RECOMMENDATIONS, {
        variables: { customerId: parseInt(customerId) },
        onCompleted: (data) => {
            const mapped = data.productRecommendations.map((product) => {
                return { ...product.product, key: product.id, timestamp: product.createdAt, confidence: product.confidence }
            })
            setProductRecommendations(mapped);
        }
    });

    const {loading: ordersLoading} = useQuery(GET_CUSTOMER_ORDERS, {
        variables: { customerId },
        onCompleted: (data) => {
            const mapped = data.customerOrders.map((order) => {
                return { ...order, key: order.id, timestamp: order.createdAt }
            })
            const product_history = data.customerOrders.filter(o => o.ordersProducts.length > 0).sort((o1, o2) => o1.createdAt - o2.createdAt)
                .map(order => {
                    return order.ordersProducts.map(op => {
                        return { ...op, key: op.id, timestamp: order.createdAt, code: op.product.code, name: op.product.name }
                    })
            }).flat()
            const order_history_hash = {}
            data.customerOrders.forEach(order => {
                const ts = order.createdAt.slice(0,7)
                order_history_hash[ts] = order_history_hash[ts] + 1 || 1
            })
            setGraphData(Object.keys(order_history_hash).map(key => {
                return { month: key, "注文数": order_history_hash[key] }
            }).sort((o1, o2) => o1.month.localeCompare(o2.month)))
            setProductHistory(product_history)
            setRows(mapped);
        }
    }); 

    const order_columns = [
        {
            title: <Typography.Text strong>データ名</Typography.Text>,
            dataIndex: 's3ObjectKey',
            render: (text, rowContent) => linkify(rowContent),
            sorter: (a, b) => a.s3ObjectKey.localeCompare(b.s3ObjectKey),
            ellipsis: true,
        },
        {
            title: <Typography.Text strong>伝票番号</Typography.Text>,
            dataIndex: 'orderNumber',
            sorter: (a, b) => a.orderNumber?.localeCompare(b.orderNumber),
            width: 110
        },
        {
            title: <Typography.Text strong>アップロード日時</Typography.Text>,
            dataIndex: 'timestamp',
            defaultSortOrder: 'ascend',
            render: timestamp => new Date(timestamp).toLocaleString(),
            sorter: (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
            multiple: 2,
            width: 200
        }
    ];

    const product_columns = [
        {
            title: <Typography.Text strong>品番</Typography.Text>,
            dataIndex: 'code'
        },
        {
            title: <Typography.Text strong>商品名</Typography.Text>,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: <Typography.Text strong>個数</Typography.Text>,
            dataIndex: 'quantity'
        }
    ];

    const recommendation_columns = [
        {
            title: <Typography.Text strong>品番</Typography.Text>,
            dataIndex: 'code'
        },
        {
            title: <Typography.Text strong>商品名</Typography.Text>,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: <Typography.Text strong>信頼度</Typography.Text>,
            dataIndex: 'confidence'
        }
    ]

    const linkify = rowContent => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
            <Link to={PATHS.orderDetails.replace(':orderId', rowContent.key)}>
                {rowContent.s3ObjectKey}
            </Link>
        </div>
    );

    return <>
        <Row gutter={24}>
            <Col span={12}>
                {customerData?.customer ? 
                <Descriptions column={2} title="得意先情報" bordered>
                    <Descriptions.Item label="得意先コード" span={2}>{customerData.customer.code}</Descriptions.Item>
                    <Descriptions.Item label="得意先名１">{customerData.customer.nameOne}</Descriptions.Item>
                    <Descriptions.Item label="得意先名２">{customerData.customer.nameTwo}</Descriptions.Item>
                    <Descriptions.Item label="得意先カナ名" span={2}>{customerData.customer.kanaName}</Descriptions.Item>
                    <Descriptions.Item label="住所" span={2}>{customerData.customer.address}</Descriptions.Item>
                    <Descriptions.Item label="電話番号">{customerData.customer.phone}</Descriptions.Item>
                    <Descriptions.Item label="FAX番号">{customerData.customer.fax}</Descriptions.Item>
                    <Descriptions.Item label="郵便番号">{customerData.customer.zipcode}</Descriptions.Item>
                </Descriptions> : <Spin />}
                <Line xField="month" yField="注文数" data={graphData} style={{height: "16rem", margin: "1rem 0"}}/>            
            </Col>
            <Col span={12}>
                {!recsLoading ? 
                <Table title={() => <Typography>オススメ商品</Typography>} columns={recommendation_columns} dataSource={productRecommendations} /> : <Spin className="spin-center"/>}
            </Col>
        </Row>
        <Row gutter={24}>
            <Col span={12}>
                {!ordersLoading ? 
                <Table title={() => <Typography>購入履歴</Typography>} columns={product_columns} dataSource={productHistory} /> : <Spin className="spin-center"/>}
            </Col>
            <Col span={12}>
                {!ordersLoading ? 
                <Table title={() => <Typography>注文データ</Typography>} columns={order_columns} dataSource={rows} /> : <Spin className="spin-center"/>}
            </Col>
        </Row>
    </>
};

export default CustomerDetails;