import React, { useState } from "react"
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_PRODUCTS } from "../../queries";
import { Table, Typography, Input, Spin } from "antd";

const ProductsTable = () => {
    const [originalRows, setOriginalRows] = useState([]);
    const [rows, setRows] = useState([]);

    useQuery(GET_ALL_PRODUCTS, {
        onCompleted: (data) => {
            const mapped = data.products.edges.map((product) => {
                return { ...product.node, key: product.node.id }
            })
            setOriginalRows(mapped);
            setRows(mapped);
        }
    }); 

    const handleSearch = (e) => {
        let filtered = [...originalRows]
        filtered = filtered.filter((row) => {
            const values = Object.values(row).join()
            return values.includes(e.target.value)
        })
        setRows(filtered)
    }

    const columns = [
        {
            title: <Typography.Text strong>商品コード</Typography.Text>,
            dataIndex: 'code',
            sorter: (a, b) => parseInt(a.code) - parseInt(b.code),
        },
        {
            title: <Typography.Text strong>商品名</Typography.Text>,
            render: (text, record) => {
                return <Link to={`/products/${record.id}`}> {record.name} </Link>
            }
        },
    ]

    if (originalRows.length === 0) {
        return <Spin style={{margin: "50%", marginTop: "40%"}}/>
    }

    return <div>
        <Input.Search placeholder={"商品コードもしくは商品名"} style={{maxWidth: "20rem", marginBottom: "1.5rem"}} onChange={handleSearch}></Input.Search>
        <Table dataSource={rows} columns={columns}/>
    </div>;
};

export default ProductsTable;