import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Alert, Typography, Form, Input, Button, Col, Checkbox, Tooltip } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { authenticationService } from '../../Utilities/authenticationService';
import './login.less';

const LoginForm = ({ history, location }) => {
    const [error, setError] = useState(false);
    const { Title } = Typography;

    if (authenticationService.currentUserValue) {
        history.push('/');
    }

    const [form] = Form.useForm();
    const [, forceUpdate] = useState(); // To disable submit button at the beginning.

    useEffect(() => {
        forceUpdate({});
    }, []);

    const onFinish = ({ username, password }) => {
        authenticationService.login(username, password).then(
            () => {
                const { from } = location.state || { from: { pathname: '/' } };
                history.push(from);
            },
            (err) => {
                console.log(`Login error: ${err}`);
                setError(true);
            }
        );
    };

    const loginErrorText = (
        <Typography.Text strong>
            ユーザーIDまたはパスワードが間違っています。パスワードは半角英数字を含む、8文字以上12文字以内で入力し、半角スペースなどが含まれていないかご確認ください。
        </Typography.Text>
    );

    return (
        <div className='login-layout'>
            <Col span={4} className='login-wrapper'>
                <Title level={4}>ログイン</Title>
                <Form form={form} name="loginform" onFinish={onFinish} initialValues={{ remember: true }}>
                    { error && <Form.Item>
                        <Alert
                            description={loginErrorText}
                            type='error'
                            showIcon
                            closable
                            onClose={() => setError(false)}
                        />
                    </Form.Item> }
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'ユーザー名を入力してください'
                            }
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="ユーザーID" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{
                            required: true,
                            message: 'パスワードを入力してください'
                        }]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="パスワード"
                        />
                    </Form.Item>
                    <Form.Item
                        name="remember"
                        valuePropName="remember"
                        style={{ textAlign: 'left' }}
                        rules={[{
                            required: false
                        }]} >
                        <Checkbox>ログイン状態で保存する</Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Tooltip title="上司もしくは管理者にパスワードリセットを申請してください">
                            <a href="passwordreset">パスワードを忘れた場合</a>
                        </Tooltip>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        { () => (
                            <Button
                                block
                                type="primary"
                                htmlType="submit"
                                disabled={
                                    !form.isFieldTouched('username') || !form.isFieldTouched('password') ||
                                        form.getFieldsError().filter(({ errors }) => errors.length).length}
                            >
                                        ログイン
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Col>
        </div>
    );
};

LoginForm.propTypes = {
    history: propTypes.object,
    location: propTypes.object
};

export default LoginForm;
