import React, { useState } from "react"
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMERS } from "../../queries";
import { Table, Typography, Input, Spin } from "antd";

const CustomersTable = () => {
    const [originalRows, setOriginalRows] = useState([]);
    const [rows, setRows] = useState([]);

    useQuery(GET_CUSTOMERS, {
        onCompleted: (data) => {
            const mapped = data.customers.edges.map((customer) => {
                return { ...customer.node, key: customer.node.id }
            })
            setOriginalRows(mapped);
            setRows(mapped);
        }
    }); 

    const handleSearch = (e) => {
        let filtered = [...originalRows]
        filtered = filtered.filter((row) => {
            const values = Object.values(row).join()
            return values.includes(e.target.value)
        })
        setRows(filtered)
    }

    const columns = [
        {
            title: <Typography.Text strong>得意先コード</Typography.Text>,
            dataIndex: 'code',
            sorter: (a, b) => parseInt(a.code) - parseInt(b.code),
        },
        {
            title: <Typography.Text strong>得意先名</Typography.Text>,
            render: (text, record) => {
                return <Link to={`/customers/${record.id}`}>
                    {record.nameTwo ? `${record.nameOne} ${record.nameTwo}` : record.nameOne}
                </Link>
            }
        },
        {
            title: <Typography.Text strong>得意先カナ名</Typography.Text>,
            dataIndex: 'kanaName',
            sorter: (a, b) => a.kanaName.localeCompare(b.kanaName),
        },
        {
            title: <Typography.Text strong>電話番号</Typography.Text>,
            dataIndex: 'phone'
        },
        {
            title: <Typography.Text strong>郵便番号</Typography.Text>,
            dataIndex: 'zipcode'
        },
        {
            title: <Typography.Text strong>FAX番号</Typography.Text>,
            dataIndex: 'fax'
        }
    ]

    if (originalRows.length === 0) {
        return <Spin style={{margin: "50%", marginTop: "40%"}}/>
    }

    return <div>
        <Input.Search placeholder={"search customers"} style={{maxWidth: "20rem", marginBottom: "1.5rem"}} onChange={handleSearch}></Input.Search>
        <Table dataSource={rows} columns={columns}/>
    </div>;
};

export default CustomersTable;