import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Typography, Form, Input,Row,Space } from 'antd';
import { CREATE_USER } from '../../mutations';
import propTypes from 'prop-types';

const AddUserModal = ({ handleCompleted, returnToTable }) => {
    const [createUser] = useMutation(CREATE_USER, {
        onCompleted: (data) => {
            handleCompleted(data.createUser.user);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const onFinish = ({ username }) => {
        createUser({ variables: { input: { username } } });
    };

    const [form] = Form.useForm();
    const [, forceUpdate] = useState(); // To disable submit button at the beginning.

    useEffect(() => {
        forceUpdate({});
    }, []);

    return <Form
        labelCol={ { span: 4 } }
        wrapperCol={ { span: 8 } }
        name='newuser'
        form={form}
        layout='vertical'
        onFinish={onFinish}
    >
        <Form.Item>
            <Typography.Title level={5}>新規追加</Typography.Title>
        </Form.Item>
        <Form.Item
            label="新規ユーザーID"
            name='username'
            rules={[{ required: true, message: 'ユーザーIDが必要です。' }]}
        >
            <Input />
        </Form.Item>
        <Row>
            <Space>
                <Form.Item shouldUpdate>
                    { () => (
                        <Button
                            disabled={ !form.isFieldTouched('username') ||
                                    form.getFieldsError().filter(({ errors }) => errors.length).length}
                            type="primary"
                            htmlType='submit'
                        >
                            追加する
                        </Button>
                    )}
                </Form.Item>
            
                <Form.Item>
                    <Button onClick={returnToTable}>
                        キャンセル
                    </Button>
                </Form.Item>
            </Space>
        </Row>
    </Form>;

};

AddUserModal.propTypes = {
    handleCompleted: propTypes.func
};

export default AddUserModal;
