import React, { useState } from 'react';
import { Menu, Button, Dropdown, Tooltip } from 'antd';
import propTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';

const BoundingBoxTypeDropdown = ({ value, onChange, onDelete }) => {
    const [tag, setTag] = useState(value ? value : 'タイプ');

    const handleClick = ({ key }) => {
        setTag(key);
        onChange(key);
    };

    const menu = <Menu onClick={handleClick}>
        <Menu.Item key={'得意先'}>
            得意先
        </Menu.Item>
        <Menu.Item key={'注文内容'}>
            注文内容
        </Menu.Item>
        <Menu.Item key={'納品先'}>
            納品先
        </Menu.Item>
    </Menu>;

    return <div style={{position: "relative", top: "-52px", left: "10px"}}>
        <Dropdown overlay={menu} placement='topCenter'>
            <Button>{ tag }</Button>
        </Dropdown>
        <Tooltip title='消します'>
            <Button shape='circle' onClick={onDelete} icon={<DeleteOutlined />} danger />
        </Tooltip>
    </div>;
};

BoundingBoxTypeDropdown.propTypes = {
    onChange: propTypes.func,
    onDelete: propTypes.func,
    value: propTypes.string
};

export default BoundingBoxTypeDropdown;
