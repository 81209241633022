import React, { useState, useEffect }from 'react';
import { Dropdown, Layout, Menu, Typography, Space } from 'antd';
import propTypes from 'prop-types';
import {
    ImportOutlined,
    LineChartOutlined,
    DownOutlined,
    UploadOutlined,
    MailOutlined,
    ContainerOutlined,
    SettingOutlined,
    LogoutOutlined,
    SearchOutlined,
    UserOutlined,
    ToolOutlined,
} from '@ant-design/icons';
import './authorizedlayout.less';
import { NavLink, useHistory } from 'react-router-dom';
import { PATHS } from '../../constants';
import { useStickyState } from '../../Utilities/useStickyState';
import { GET_TAB_COUNT } from '../../queries';
import { useQuery } from '@apollo/client';
import consumerWithToken from '../../cable';
import { EMAIL_ORDERS_TAB_MAPPING } from '../../constants';

const { Header, Content, Sider } = Layout;

const AuthorizedLayout = ({ children, disableBorder, onLogout }) => {
    const history = useHistory()
    const [collapsed, setCollapsed] = useStickyState(false, 'screwdriverAuthorizedLayoutCollapsed');
    const [faxesCount, setFaxesCount] = useState({})
    const { data: faxesData } = useQuery(GET_TAB_COUNT, {
        fetchPolicy: 'network-only',
        variables: { activeTab: null },
    });

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('currentUser'))?.token;
        const consumer = consumerWithToken(token)
        consumer.subscriptions.create({ channel: 'TabCountsChannel' }, {
            received: (data) => {
                setFaxesCount(data.body)
            }
        })
        
        return () => {
            consumer.disconnect()
        }
    }, [])

    useEffect(() => {
        if (faxesData) {
            setFaxesCount(faxesData.tabCounts)
        }
    }, [faxesData])
    const historyToViewText = (historyPath) => {
        // eslint-disable-next-line
        switch (historyPath) {
            case PATHS.upload:
                return "アップロード"
            case PATHS.orders:
                return "全てのデータ"
            case PATHS.import:
                return "販売管理データインポート"
            case PATHS.detailSearch:
                return "データ検索"
            case PATHS.dashboard:
                return "進捗状況ダッシュボード"
            case PATHS.userSettings:
                return "設定"
            case PATHS.customers:
                return "得意先ダッシュボード"
        }
        if (historyPath.includes(PATHS.customers)) { // case can't handle includes for customerDetails path
            return "得意先ダッシュボード"
        }
    }
    
    const refreshPath = (current) => {
        if (current === history.location.pathname) { // if current path matches the attempted pushed path, go to /null, then go back to refresh page
            history.push('/null')
            history.goBack()
        }
    }

    const handleOrdersTable = (e) => {
        window.localStorage.setItem('screwdriverOrdersTableActiveTab', JSON.stringify(e.key))
        refreshPath(PATHS.orders)
        history.push(PATHS.orders)
    }
    
    const handleEmailsTable = (e) => {
        window.localStorage.setItem('screwdriverEmailsTableActiveTab', JSON.stringify(e.key))
        refreshPath(PATHS.emails)
        history.push(PATHS.emails)
    }
    

    const Sidebar = <Sider 
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        width={200} 
        style={{position:"fixed", zIndex:"11"}} 
        className="site-layout-background site-layout-sidebar">
        
        {collapsed ? 
        <img style={{ margin: '20px 0px 18px 21px' }} src={require('../../static/BestPartsLogoCollapsed.png')} alt='bestpartslogocollapsed' className='app-logo-collapsed' />
        :
        <img style={{ margin: '20px 0px 18px 21px' }} src={require('../../static/BestPartsLogo.png')} alt='bestpartslogo' className='app-logo' />
        }
        <Menu
            mode="inline"
            defaultOpenKeys={['data', 'emails']}
            style={{ height: '100vh', borderRight: 0 }}
        >
                
            <Menu.Item icon={<UploadOutlined />} key="1" onClick={e => refreshPath(PATHS.upload)}>
                <NavLink to={PATHS.upload}>アップロード</NavLink>
            </Menu.Item>
            <Menu.SubMenu title="全てのファックス" key="data" icon={<ContainerOutlined />} onClick={() => refreshPath(PATHS.orders)} mode="inline">
                <Menu.Item key="uploaded" label="アップロード済み" onClick={e => handleOrdersTable(e)}>
                    <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div>アップロード済み</div>
                        <div>{faxesCount.uploaded || "0"}</div>
                    </Space>
                </Menu.Item>
                <Menu.Item key="incomplete" label="未完了" onClick={e => handleOrdersTable(e)}>
                    <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div>未完了</div>
                        <div>{faxesCount.incomplete || "0"}</div>
                    </Space>
                    </Menu.Item>
                <Menu.Item key="backordered" label="不足・取寄" onClick={e => handleOrdersTable(e)}>
                    <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div>不足・取寄</div>
                        <div>{faxesCount.backordered || "0"}</div>

                    </Space>
                </Menu.Item>
                <Menu.Item key="complete" label="受注入力完了" onClick={e => handleOrdersTable(e)}>
                    <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div>受注入力完了</div>
                        <div>{faxesCount.complete || "0"}</div>
                    </Space>
                </Menu.Item>
            </Menu.SubMenu>
            { JSON.parse(localStorage.currentUser).admin &&
                <Menu.SubMenu key="emails" title='メール' icon={<MailOutlined />}  onClick={e => refreshPath(PATHS.emails)} mode='inline'>
                    { Object.entries(EMAIL_ORDERS_TAB_MAPPING).map(([key, value]) => (
                        <Menu.Item key={key} label={value} onClick={e => handleEmailsTable(e)}>
                            <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div>{value}</div>
                            </Space>
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
                
            }
            <Menu.Item key="search" icon={<SearchOutlined />} onClick={e => refreshPath(PATHS.detailSearch)}>
                <NavLink to={PATHS.detailSearch}>データ検索</NavLink>
            </Menu.Item>
            <Menu.Item key="import" icon={<ImportOutlined />} onClick={e => refreshPath(PATHS.import)}>
                <NavLink to={PATHS.import}>販売管理データインポート</NavLink>
            </Menu.Item>
            { JSON.parse(localStorage.currentUser).admin &&
                <Menu.Item key="dashboard" icon={<LineChartOutlined />} onClick={e => refreshPath(PATHS.dashboard)}>
                    <NavLink to={PATHS.dashboard}>進捗状況ダッシュボード</NavLink>
                </Menu.Item>
            }
            <Menu.Item key={"customers"} icon={<UserOutlined />} onClick={e => refreshPath(PATHS.customers)}>
                <NavLink to={PATHS.customers}>得意先ダッシュボード</NavLink>
            </Menu.Item>
            <Menu.Item key={"salesPredictions"} icon={<ToolOutlined />} onClick={e => refreshPath(PATHS.products)}>
                <NavLink to={PATHS.products}>予測ダッシュボード</NavLink>
            </Menu.Item>
        </Menu>
    </Sider>;

    const UserMenu = <Menu key="user-panel" className="user-panel">
        <Menu.Item icon={<SettingOutlined />} key="settings"><NavLink to={PATHS.userSettings}>設定</NavLink></Menu.Item>
        <Menu.Item icon={<LogoutOutlined />} key='logout' onClick={onLogout}>ログアウト</Menu.Item>
    </Menu>;

    const Topbar = <Header className="site-layout-background main-header" style={{width: collapsed ? 'calc(100%  - 80px)' : 'calc(100% - 200px)'}}>
        <Typography.Text strong style={{float: "left"}}>{historyToViewText(history.location.pathname)}</Typography.Text>
        <Dropdown overlay={UserMenu} trigger={['click']} placement='bottomRight'>
            <div style={{float: "right"}}>{ JSON.parse(localStorage.currentUser).username } <DownOutlined /></div>
        </Dropdown>
    </Header>;

    return <Layout>
        <Layout>
            { Sidebar }
            <Layout style={{marginLeft: collapsed ? 80 : 200}}>
                { Topbar }
                <Content
                    className={ disableBorder ? '' : 'site-layout-background' }
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                        marginTop: 64
                    }}
                >
                    { children }
                </Content>
            </Layout>
        </Layout>
    </Layout>;
};

AuthorizedLayout.propTypes = {
    children: propTypes.element,
    onLogout: propTypes.func,
    disableBorder: propTypes.bool
};

export default AuthorizedLayout;
