import { useDropzone } from 'react-dropzone';

export const useS3Upload = ({ onUploadStart }) => {
    const onDrop = (acceptedFiles) => {
        onUploadStart(acceptedFiles);
    };

    return useDropzone({
        accept: 'application/pdf',
        onDrop,
        noDragEventsBubbling: true
    });
};

export const uploadToS3 = async (file, presignedUploadUrl, onError, onUploadReady) => {
    // Upload the file to our pre-signed URL.
    const response = await fetch(
        new Request(presignedUploadUrl, {
            method: 'PUT',
            body: file,
            headers: new Headers({
                'Content-Type': 'application/pdf'
            })
        })
    );

    if (response.status !== 200) {
        // The upload failed, so let's notify the caller.
        onError && onError(response);
        return;
    }

    onUploadReady && onUploadReady();
};

