import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
    Col,
    Row,
    Button,
    Empty,
    Spin
} from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { GET_PRESIGNED_UPLOAD_URL } from '../../queries';
import { CREATE_ORDER, TRIGGER_INFERENCE } from '../../mutations';
import { uploadToS3, useS3Upload } from '../../Utilities/useS3Upload';
import { useLazyQuery, useMutation } from '@apollo/client';
import './uploadarea.less';
import { PATHS } from '../../constants';

const UploadArea = () => {
    const [files, setFiles] = useState();
    const [failedFiles, setFailedFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [additionalFailedMessage, setAdditionalFailedMessage] = useState(null);
    const [currentStatus, setCurrentStatus] = useState('beforeUpload');
    const [triggerInference] = useMutation(TRIGGER_INFERENCE)

    const [createOrder] = useMutation(CREATE_ORDER, {
        onError: (error) => {
            if (error.message === ("GraphQL error: has already been taken")) {
                setAdditionalFailedMessage("(ファイル名が重複しているため)")
            }
            setFailedFiles([...failedFiles, currentFile]);
            if (files.length >= 1) {
                getPresignedUploadUrl({ variables: { objectKey: files[0].name } });
            } else {
                setCurrentStatus('uploadFinished');
                setCurrentStatus('createdOrder');
            }
        },
        onCompleted: (data) => {
            triggerInference({ variables: { input: { id: data.createOrderFromFrontend.order.id } } })
            if (files.length >= 1) {
                // Upon completion - recurse until the stack is empty
                getPresignedUploadUrl({ variables: { objectKey: files[0].name } });
            } else {
                setCurrentStatus('uploadFinished');
                setCurrentStatus('createdOrder');
            }
        }
    });

    const [getPresignedUploadUrl, { loading, data }] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            // Pop 1st file from the stack of files
            const file = files.shift();
            setCurrentFile(file.name);
            setFiles(files);
            uploadToS3(
                file,
                data.presignedUploadUrl.uploadUrl,
                (response) => {
                    console.log('ERROR! ' + response.code);
                    setFailedFiles([...failedFiles, currentFile]);
                },
                () => {
                    createOrder({ variables: { input: {
                        s3ObjectKey: file.name,
                        progress: 'uploaded',
                        userId: JSON.parse(localStorage.currentUser).id
                    } } });
                }
            );
        }
    });

    const { getRootProps, getInputProps } = useS3Upload({
        presignedUploadUrl: data && data.presignedUploadUrl.uploadUrl,
        onUploadStart: acceptedFiles => {
            // Preload all files here in a stack 'files'
            setFiles(acceptedFiles);
            setCurrentStatus('loadingUpload');
            getPresignedUploadUrl({ variables: { objectKey: acceptedFiles[0].name } });

        }
    });

    const description = <React.Fragment>
        アップロードしたい受注データをここにドラッグ＆ドロップ
        <br/>
        または
    </React.Fragment>;

    if (loading) { return <SyncOutlined spin />; }

    return <Col>
        { currentStatus === 'beforeUpload' &&
            <>
                <Row justify='center'>
                    <Empty
                        description={description}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        id='upload-zone'
                        {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Row justify='center'>
                            <Button type="primary">ファイルを選択</Button>
                        </Row>
                    </Empty>
                </Row>
            </>
        }
        { currentStatus === 'loadingUpload' &&
            <>
                <Row justify='center'>
                    <Spin size='large' />
                </Row>
                <br/>
                <Row justify='center'>
                    読み込み中
                </Row>
                <Row justify='center'>
                    { currentFile }
                </Row>
            </>
        }
        { currentStatus === 'createdOrder' &&
            <Redirect to={
                {
                    pathname: PATHS.orders,
                    state: { failedFiles, additionalFailedMessage: additionalFailedMessage}
                }
            } />
        }
    </Col>;
};

export default UploadArea;
