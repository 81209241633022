import React from 'react';
import propTypes from 'prop-types';
import { AuthorizedLayout } from '../';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '../../Utilities/authenticationService';
import { PATHS } from '../../constants';

const BetaRoute = ({ component: Component, disableBorder, onLogout, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: PATHS.login, state: { from: props.location } }} />;
        }

        if (!currentUser.admin) {
            // user is not an admin, redirect to home or unauthorized page
            return <Redirect to={{ pathname: PATHS.home }} />;
        }

        // authorized admin, return component
        return <AuthorizedLayout onLogout={onLogout} disableBorder={disableBorder}>
            <Component {...props} />
        </AuthorizedLayout>;
    }} />
);

BetaRoute.propTypes = {
    location: propTypes.object,
    component: propTypes.func,
    disableBorder: propTypes.bool,
    onLogout: propTypes.func
};

export default BetaRoute;
