import React, { useState } from 'react';
import {
    Row,
    Button
} from 'antd';
import { SyncOutlined, UploadOutlined } from '@ant-design/icons';
import { GET_PRESIGNED_UPLOAD_URL } from '../../queries';
import { CREATE_ORDER, TRIGGER_INFERENCE } from '../../mutations';
import { uploadToS3, useS3Upload } from '../../Utilities/useS3Upload';
import { useLazyQuery, useMutation } from '@apollo/client';
import './uploadarea.less';
import { closableMessage } from '../../Utilities/closableMessage';

const UploadButton = ({showMessage = false, faxListRefetch = null, uploading, setUploading}) => {
    const [files, setFiles] = useState();
    const [failedFiles, setFailedFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [totalFileNumber, setTotalFileNumber] = useState(0);
    const [triggerInference] = useMutation(TRIGGER_INFERENCE)

    const [createOrder] = useMutation(CREATE_ORDER, {
        onError: (error) => {
            if (error.message === ("GraphQL error: has already been taken")) {
                closableMessage('uploadError', 'error', `${currentFile}がアップロードできませんでした。ファイル名が重複しています。`, 0)
            }
            const newFailedFiles = [...failedFiles, currentFile];
            setFailedFiles(newFailedFiles);
            if (files.length >= 1) {
                getPresignedUploadUrl({ variables: { objectKey: files[0].name } });
            }
            if (files.length === 0) {
                showMessage && closableMessage('uploadSuccess', 'success', `アップロードが完了しました。(${totalFileNumber - files.length - newFailedFiles.length}/${totalFileNumber})`);
                setUploading(false);
            }
        },
        onCompleted: (data) => {
            triggerInference({ variables: { input: { id: data.createOrderFromFrontend.order.id } } })
            if (files.length >= 1) {
                // Upon completion - recurse until the stack is empty
                getPresignedUploadUrl({ variables: { objectKey: files[0].name } });
            }
            if (faxListRefetch) {
                localStorage.getItem('screwdriverOrderListIds') && localStorage.removeItem('screwdriverOrderListIds');
                faxListRefetch();
            }
            if (files.length === 0) {
                showMessage && closableMessage('uploadSuccess', 'success', `アップロードが完了しました。(${totalFileNumber - files.length - failedFiles.length}/${totalFileNumber})`);
                setUploading(false);
            }
        }
    });

    const [getPresignedUploadUrl, { loading, data }] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            // Pop 1st file from the stack of files
            const file = files.shift();
            setCurrentFile(file.name);
            setFiles(files);
            uploadToS3(
                file,
                data.presignedUploadUrl.uploadUrl,
                (response) => {
                    console.log('ERROR!' + response.code);
                    setFailedFiles([...failedFiles, currentFile]);
                },
                () => {
                    createOrder({ variables: { input: {
                        s3ObjectKey: file.name,
                        progress: 'uploaded',
                        userId: JSON.parse(localStorage.currentUser).id
                    } } })
                }
            );
        }
    });

    const { getRootProps, getInputProps } = useS3Upload({
        presignedUploadUrl: data && data.presignedUploadUrl.uploadUrl,
        onUploadStart: acceptedFiles => {
            setTotalFileNumber(acceptedFiles.length);
            setUploading(true);
            // Preload all files here in a stack 'files'
            setFiles(acceptedFiles);
            getPresignedUploadUrl({ variables: { objectKey: acceptedFiles[0].name } });

        }
    });

    if (loading) { return <SyncOutlined spin />; }

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Row justify="center">
                <Button disabled={uploading} icon={<UploadOutlined/>}>{uploading ? `アップロード中 ${totalFileNumber - files?.length}/${totalFileNumber}` : 'Faxをアップロード'}</Button>
            </Row>
        </div>
    )
};

export default UploadButton;
